import { TFuncKey, useTranslation } from 'react-i18next'

import { StatusIcon } from '../../../statusBadge/StatusBadge'

import { Action } from './Action'
import { BankDetailsReviewRequestStatusLog } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { BankDetailsStatusActionProps } from './BankDetailsStatusAction.types'
import styles from './BankDetailsStatusAction.module.scss'

const statusToTextMap: Record<BankDetailsReviewRequestStatusLog['action'], TFuncKey<'adminDashboard'>> = {
  requested: 'bankDetails.review.requested',
  approved: 'bankDetails.review.approved',
  rejected: 'bankDetails.review.rejected',
  deleted: 'bankDetails.review.deleted'
}

export function BankDetailsStatusAction({ log }: BankDetailsStatusActionProps) {
  const { t } = useTranslation()
  const { action } = log

  return (
    <Action>
      <StatusIcon status={action === 'requested' ? 'pending' : action} className={styles.actionIcon} />
      <span>{t(statusToTextMap[action])}</span>
    </Action>
  )
}
