import React from 'react'
import styled from 'styled-components'
import { styledVariant } from '../../util'
import { SearchInputProps, StyledSearchInputProps } from './search-input.types'
import { inputStatusStyles } from '../../shared/input-status'
import { Icon } from '../icon'
import { Button } from '../button'
import { Feedback } from '../feedback'

export const StyledSearchContainer = styled.div<{
  width?: string | number
}>`
  ${({ width, theme }) => `
    position: relative;
    width: 100%;
    ${width && `max-width: ${width};`}

  `}
`

export const StyledIconContainer = styled.div`
  ${({ theme }) => `
    position: absolute;
    z-index: 1;
    margin: ${theme.sizes[2]};
  `}
`

export const StyledClearButton = styled.div`
  ${({ theme }) =>
    `
      display: inline-block;
      background: transparent;
      border-color: transparent;
      position: absolute;
      right: 0%;
      z-index: 1;
      margin: 0.2rem 0.1rem;
    }

    button {
      padding: 0 0.6rem;

      &:focus-visible {
        outline: none;
        border: none;
      }
    }

    svg {
      color: ${theme.colors.gray500};
      width: ${theme.sizes[6]};
      height: ${theme.sizes[6]};
    }
  `}
`

export const StyledSearchInput = styled.input<StyledSearchInputProps>`
  ${styledVariant('$status', inputStatusStyles)};

  ${({ theme }) =>
    `
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: ${theme.sizes[10]};
    border-radius: ${theme.borderRadii.medium};
    outline: none;
    padding: 0 2.5rem;
    font-size:  ${theme.fontSizes.bodySmall};
    color: ${theme.colors.black};

    ::placeholder {
      color: ${theme.colors.gray600};
    }

    &:disabled {
      background-color: ${theme.colors.gray80};
      outline: none;
      border: none;
    }

    @media only screen and (max-device-width: 600px) {
      -webkit-appearance: none;
      font-size: ${theme.sizes[4]};
    },
  `}
`

export const SearchInput = ({
  status = 'default',
  statusMessage,
  handleClearValue,
  width,
  ...props
}: SearchInputProps) => {
  const showStatus = status === 'danger' && statusMessage

  return (
    <StyledSearchContainer width={width}>
      <StyledIconContainer>
        <Icon name="search" size={6} color="gray500" />
      </StyledIconContainer>
      {props.value && (
        <StyledClearButton>
          <Button
            data-testid="clear-button"
            endIcon="reject"
            variant="tertiary"
            size="small"
            onPress={handleClearValue}
          />
        </StyledClearButton>
      )}
      <StyledSearchInput type="text" {...props} $status={status} />
      {showStatus && <Feedback variant="critical">{statusMessage}</Feedback>}
    </StyledSearchContainer>
  )
}
