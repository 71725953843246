import { getApiDomainFromHostname } from '@percent/utility'
import { environments } from './constants/environments'

export const environment = process.env.NX_REACT_APP_ENV

const envConfig = (() => {
  const appDomain = getApiDomainFromHostname()
  const obj = {
    urls: {
      api: '',
      app: ''
    },
    keys: {
      ga: '',
      client_id: '',
      pk: ''
    },
    donation: {
      donationRequestPreviewUrl: ''
    },
    disbursement: {
      disbursementPreviewUrl: ''
    },
    donationMatchRequest: {
      dashboardLink: ''
    }
  }

  obj.disbursement.disbursementPreviewUrl =
    'https://eu-west-1.quicksight.aws.amazon.com/sn/dashboards/c634b1bf-48bf-4fdf-b9df-e2e340ff240c'
  obj.donation.donationRequestPreviewUrl =
    'https://eu-west-1.quicksight.aws.amazon.com/sn/dashboards/82f1b193-e051-46d7-bd8c-c5ef7fa47fc1'
  obj.donationMatchRequest.dashboardLink =
    'https://metabase.pbp-data.com/dashboard/2717-pending-donation-match-requests'
  obj.keys.pk = process.env.NX_REACT_APP_PK!

  switch (environment) {
    case environments.LOCAL_SERVER:
    case 'Test':
      obj.urls.api = 'http://localhost:8080/'
      obj.urls.app = 'http://localhost:3000'
      obj.keys.ga = ''
      break
    case environments.LOCAL:
      obj.urls.api = `https://staging-api.${appDomain}/`
      obj.urls.app = 'http://localhost:3000'
      obj.keys.ga = ''
      break
    case environments.STAGING:
      obj.urls.api = `https://staging-api.${appDomain}/`
      obj.urls.app = `https://staging-admin.${appDomain}`
      obj.keys.ga = ''
      break
    case environments.PREPROD:
      obj.urls.api = 'https://api.preproduction.goodstack.io/'
      obj.urls.app = `https://admin.preproduction.${appDomain}`
      obj.keys.ga = ''
      break
    case environments.QA:
      obj.urls.api = `https://qa-api.${appDomain}/`
      obj.urls.app = `https://qa-admin.${appDomain}`
      obj.keys.ga = ''
      break
    case environments.SANDBOX:
      obj.urls.api = `https://sandbox-api.${appDomain}/`
      obj.urls.app = `https://sandbox-admin.${appDomain}`
      obj.keys.ga = ''
      break
    case environments.PRODUCTION:
      obj.urls.api = `https://api.${appDomain}/`
      obj.urls.app = `https://admin.${appDomain}/`
      obj.keys.ga = ''
      obj.disbursement.disbursementPreviewUrl =
        'https://eu-west-1.quicksight.aws.amazon.com/sn/dashboards/c634b1bf-48bf-4fdf-b9df-e2e340ff240c'
      obj.donation.donationRequestPreviewUrl =
        'https://eu-west-1.quicksight.aws.amazon.com/sn/dashboards/82f1b193-e051-46d7-bd8c-c5ef7fa47fc1'
      obj.donationMatchRequest.dashboardLink =
        'https://metabase.pbp-data.com/dashboard/2717-pending-donation-match-requests'
      break
    default:
      obj.urls.api = `https://staging-api.${appDomain}/`
      break
  }

  return obj
})()

export const config = {
  ...envConfig,
  version: '1.0.0'
}
