import { DomainVerificationProps } from './DomainVerification.types'
import styles from './DomainVerification.module.scss'
import { DomainAnalysis } from './domainAnalysis/DomainAnalysis'
import { OpenAiAnalysis } from './openAiAnalysis/OpenAiAnalysis'

export function DomainVerification({ domainVerification }: Readonly<DomainVerificationProps>) {
  if (Object.keys(domainVerification).length === 0) return null

  return (
    <div className={styles.fraudAnalysisContainer} data-testid="domain-verification">
      {domainVerification.domains?.map(domain => (
        <DomainAnalysis key={domain.domain} domain={domain} />
      ))}
      {domainVerification.openAiAnalysis && <OpenAiAnalysis openAiAnalysis={domainVerification.openAiAnalysis} />}
    </div>
  )
}
