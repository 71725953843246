import { SimilarOrganisation } from '@percent/admin-dashboard/api/types'

export type SimilarOrganisationFormValues = {
  action?: SimilarOrganisationActionValues | ''
  organisationId?: string
  organisation?: SimilarOrganisation
}

export type SimilarOrganisationsProps = {
  similarOrganisations?: SimilarOrganisation[] | null
  isLoading: boolean
  registryInformationNotFound?: boolean
  isApproved: boolean
  isIndianNonprofit?: boolean
}

export enum SimilarOrganisationActionValues {
  MERGE = 'merge',
  IGNORE = 'ignore',
  BRANCH_CHILD = 'branch_child',
  BRANCH_PARENT = 'branch_parent',
  FISCAL_SPONSORSHIP_CHILD = 'fiscal_sponsorship_child',
  FISCAL_SPONSORSHIP_PARENT = 'fiscal_sponsorship_parent'
}

export enum RelationshipValues {
  CHILD = 'child',
  PARENT = 'parent'
}

export type SimilarOrganisationDropdownOptions = { label: string; value: SimilarOrganisationActionValues }[]
