import { useEffect, useState } from 'react'
import { DialogContent, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Dialog, PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as RejectedIcon } from '@percent/admin-dashboard/common/assets/images/rejected.svg'
import styles from './BankDetailsAddNoteFormErrorDialog.module.scss'
import { BankDetailsAddNoteFormErrorDialogProps } from './BankDetailsAddNoteFormErrorDialog.types'

export function BankDetailsAddNoteFormErrorDialog({ error, setError }: BankDetailsAddNoteFormErrorDialogProps) {
  const { t } = useTranslation()
  const [dialogState, setDialogState] = useState(error)

  const handleClose = () => {
    setError(false)
  }

  useEffect(() => {
    setDialogState(error)
  }, [error, setDialogState])

  return (
    <Dialog withoutHeader openModal={dialogState} onClose={handleClose}>
      <DialogContent>
        <RejectedIcon />
        <Typography variant="h5" align="center">
          {t('errorMessage.errorHasOccurred')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('dialog.bankDetails.noteError')}
        </Typography>
        <div className={styles.singleButtonWrapper}>
          <PercentButton title={t('button.done')} onClick={handleClose} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
