import { rest } from 'msw'

import { mockValidationSubmissionDetailsResponse } from '@percent/admin-dashboard/fixtures/api/validationSubmissions/mockValidationSubmissionDetailsResponse'
import { mockValidationSubmissionsResponse } from '@percent/admin-dashboard/fixtures/api/validationSubmissions/mockValidationSubmissionsResponse'
import { mockValidationSubmissionLogsResponse } from '@percent/admin-dashboard/fixtures/api/validationSubmissions/mockValidationSubmissionLogsResponse'
import { config } from '@percent/admin-dashboard/config'
import { mockValidationSubmissionConfigurationsResponse } from '@percent/admin-dashboard/fixtures/api/validationSubmissions/mockValidationSubmissionConfigurationsResponse'
import { mockValidationSubmissionConfigurationResponse } from '@percent/admin-dashboard/fixtures/api/validationSubmissions/mockValidationSubmissionConfigurationResponse'

export const validationSubmissionsHandlers = [
  rest.get(`${config.urls.api}v1/admin/validation-submissions`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationSubmissionsResponse))
  }),
  rest.get(`${config.urls.api}v1/admin/validation-submissions/:id`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationSubmissionDetailsResponse))
  }),
  rest.get(`${config.urls.api}v1/admin/validation-submissions/:id/logs`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationSubmissionLogsResponse))
  }),
  rest.get(
    `${config.urls.api}v1/admin/partners/:partnerId/validation-submission-configurations`,
    async (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockValidationSubmissionConfigurationsResponse))
    }
  ),
  rest.get(`${config.urls.api}v1/admin/validation-submission-configurations/:id`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationSubmissionConfigurationResponse))
  })
]
