import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { FileWithPath } from '@mantine/dropzone'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Dropzone, Modal } from '@percent/lemonade'
import { Card } from '@percent/admin-dashboard/common/components/card/Card'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

type UploadDocumentsModalProps = {
  open: boolean
  onClose: VoidFunction
  refresh: VoidFunction
  refreshTimeline: VoidFunction
}

const TEN_MEGABYTES = 10 * 1024 * 1024
const MAX_FILE_SIZE = '10MB'
const FILES_LIMIT = 5

export function UploadDocumentsModal({ open, onClose, refresh, refreshTimeline }: UploadDocumentsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { bankDetailsService } = useServices()
  const [selectedFiles, setSelectedFiles] = useState<FileWithPath[]>([])
  const [{ isLoading, success, errorMessage }, { apiFunc }] = useMutation(bankDetailsService.uploadAdditionalDocuments)

  const handleCloseModal = () => {
    onClose()
    refresh()
    refreshTimeline()
  }

  const handleUploadDocumentOnly = () => {
    if (!selectedFiles.length) {
      return
    }

    const formData = new FormData()
    selectedFiles.forEach(file => formData.append('proofOfBankDetails', file))

    apiFunc({
      reviewId: id,
      payload: {
        formData
      }
    })
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.uploadDocumentsSuccessModal.title')}
      description={t('dialog.uploadDocumentsSuccessModal.subTitle')}
      buttonText={t('button.done')}
      handleClose={handleCloseModal}
      viewTestId="success-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )
  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.uploadDocumentsErrorModal.title')}
      description={t('dialog.uploadDocumentsErrorModal.subTitle')}
      buttonText={t('button.done')}
      handleClose={handleCloseModal}
      viewTestId="error-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="upload-bank-details-documents-modal">
      {successModal || errorModal || (
        <ActionModal
          title="Documents upload"
          primaryButtonText={t('button.uploadDocuments')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          disabled={!selectedFiles.length}
          loading={isLoading}
          handleClose={onClose}
          handleSubmit={handleUploadDocumentOnly}
          primaryBtnTestId="upload-bank-details-documents-submit-button"
          secondaryBtnTestId="upload-bank-details-documents-cancel-button"
        >
          <Card size="auto">
            <Dropzone
              description={t('typography.uploadDocumentsDescription', {
                maxFiles: FILES_LIMIT,
                size: MAX_FILE_SIZE
              })}
              files={selectedFiles}
              onChange={files => setSelectedFiles(files)}
              title={t('typography.uploadDocumentsTitle')}
              accept={['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']}
              maxFiles={5}
              maxSize={TEN_MEGABYTES}
              testId="upload-documents-dropzone"
              filePreviewTestId="upload-documents-dropzone-preview"
              disableFsAccessApi
            />
          </Card>
        </ActionModal>
      )}
    </Modal>
  )
}
