import { DomainVerificationResponse } from '@percent/admin-dashboard/api/types'

export const mockDomainVerificationResponse: { data: DomainVerificationResponse } = {
  data: {
    result: {
      openAiAnalysis: {
        fraudIndicators: {
          suspicious: true,
          analysis:
            'The applicant’s email doesn’t match their name, which raises a concern. Additionally, there is a mismatch between the organization’s website domain and the email domain, indicating potential inconsistencies:',
          suspiciousIndicators: [
            'Applicant’s email john@thefarmnimalsanctuary.com does not align with the name John Doe',
            'Mismatch between organization website domain thefarmanimalsanctuary.co.uk and email domain thefarmnimalsanctuary.com'
          ]
        },
        languageMatch: {
          match: true,
          analysis:
            'Vietnamese (VI) is not an official or widely spoken language spoken language in the United Kingdmon. The primary language in the UK is English (en-GB)'
        }
      },
      globalFlags: ['applicant_email_common_domain'],
      domains: [
        {
          source: 'organisation_website',
          domain: 'thefarmanimalsanctuary.co.uk',
          geoData: {
            ip: 'ipAddress',
            isp: 'DigitalOcean',
            as: {
              asn: '12345'
            },
            location: {
              country: 'US',
              region: 'California',
              city: 'San Francisco'
            }
          },
          whoisData: {
            createdDate: '2024-03-22T12:22:40.973Z',
            estimatedDomainAge: 1255,
            registrarName: 'GoDaddy'
          },
          flags: ['empty_website']
        }
      ]
    }
  }
}
