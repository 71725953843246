import {
  EligibilityCheck,
  ValidationSubmissionConfigurationResponse
} from '@percent/admin-dashboard/api/actions/validationSubmissions/validationSubmissions.types'
import { VerificationSubmissionConfiguration } from '@percent/domain/verifications'

export function mapToVerificationConfiguration(
  config: ValidationSubmissionConfigurationResponse
): VerificationSubmissionConfiguration {
  // Find the compliance check to get flags
  const complianceCheck = config.configuration.checks.find(check => check.type === 'compliance')
  const complianceFlags = complianceCheck && 'flags' in complianceCheck ? complianceCheck.failingFlags : undefined

  // Map allowed organization types
  const allowedOrganisationsTypes = config.configuration.allowedOrganisations.types

  // Map allowed activity sub tags - we just need the IDs
  const allowedActivitySubTags = config.allowedActivitySubTags.map(tag => tag.id)

  const exceptionRules =
    (config.configuration.checks.find(check => check.type === 'eligibility') as EligibilityCheck)?.rules?.map(
      ({ name }) => name || null
    ) || []

  return {
    id: config.id,
    name: config.name,
    allowedOrganisationsTypes,
    allowedActivitySubTags,
    complianceFlags,
    // Add any exception rules if they exist in the future
    exceptionRules
  }
}
