import React from 'react'
import styled from 'styled-components'
import { SpinnerProps, StyledSpinnerProps } from './spinner.types'
import { theme } from '../../theme'

export const StyledSpinnerContainer = styled.div<StyledSpinnerProps>`
  display: inline-block;
  ${({ $size, $color }) => `
    width: ${theme.sizes[$size]};
    height: ${theme.sizes[$size]};
    vertical-align:top;
    & > span {
      width: inherit;
      height: inherit;
      border: calc(${theme.sizes[$size]} / 8) solid
      ${$color === 'dark' ? theme.colors.gray700 : theme.colors.white};
  `}

  border-bottom-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  font-size: 0px;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Spinner = ({
  size = 'm',
  color = 'dark',
  'aria-label': ariaLabel = 'Loading',
}: SpinnerProps) => (
  <StyledSpinnerContainer
    $size={size}
    $color={color}
    role="progressbar"
    aria-label={ariaLabel}
    data-testid="spinner"
  >
    <span />
  </StyledSpinnerContainer>
)
