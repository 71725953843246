import { Grid, Paper, Typography } from '@material-ui/core'
import { FormikErrors, FormikValues, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import styles from '../common/Section.module.scss'

import { Alert, Badge, Select, FormField, Spacer } from '@percent/lemonade'
import { Loader } from '@percent/admin-dashboard/common/components'
import {
  SimilarOrganisationsProps,
  SimilarOrganisationDropdownOptions,
  SimilarOrganisationActionValues,
  SimilarOrganisationFormValues
} from './SimilarOrganisations.types'
import { renderAddressString } from '@percent/admin-dashboard/common/utility/renderAddressString'

export function SimilarOrganisations({
  similarOrganisations,
  isLoading,
  registryInformationNotFound,
  isApproved,
  isIndianNonprofit
}: Readonly<SimilarOrganisationsProps>) {
  const { t } = useTranslation()
  const { values, errors, touched, setValues } = useFormikContext<SimilarOrganisationFormValues>()

  const organisationOptions = similarOrganisations?.length
    ? similarOrganisations.map(similarOrganisation => ({
        label: similarOrganisation.name,
        value: similarOrganisation.id,
        description: renderAddressString(similarOrganisation.address)
      }))
    : []

  const options: SimilarOrganisationDropdownOptions = [
    {
      label: t('form.similarOrganisations.option.merge'),
      value: SimilarOrganisationActionValues.MERGE
    },
    {
      label: t('form.similarOrganisations.option.confirmNotADuplicate'),
      value: SimilarOrganisationActionValues.IGNORE
    },
    ...(isIndianNonprofit
      ? [
          {
            label: t('form.relatedOrganisations.option.branchChild'),
            value: SimilarOrganisationActionValues.BRANCH_CHILD
          },
          {
            label: t('form.relatedOrganisations.option.branchParent'),
            value: SimilarOrganisationActionValues.BRANCH_PARENT
          },
          {
            label: t('form.relatedOrganisations.option.fiscalSponsorshipChild'),
            value: SimilarOrganisationActionValues.FISCAL_SPONSORSHIP_CHILD
          },
          {
            label: t('form.relatedOrganisations.option.fiscalSponsorshipParent'),
            value: SimilarOrganisationActionValues.FISCAL_SPONSORSHIP_PARENT
          }
        ]
      : [])
  ]

  const showAlert = (formErrors: FormikErrors<SimilarOrganisationFormValues>, formValues: FormikValues) => {
    if (similarOrganisations?.length && !formValues?.action) {
      return true
    }

    if (Object.keys(formErrors).length) {
      return true
    }

    return false
  }

  const showParentSelect =
    isIndianNonprofit &&
    [SimilarOrganisationActionValues.BRANCH_CHILD, SimilarOrganisationActionValues.FISCAL_SPONSORSHIP_CHILD].includes(
      values.action as SimilarOrganisationActionValues
    )

  const showMergeSelect = values.action === SimilarOrganisationActionValues.MERGE

  return (
    <Grid
      container
      spacing={0}
      component={Paper}
      elevation={0}
      className={styles.container}
      data-testid="similar-organisations"
    >
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.similarOrganisations')}</Typography>
      </Grid>
      {isLoading && registryInformationNotFound ? (
        <Loader />
      ) : (
        <>
          {!isApproved && showAlert(errors, values) && (
            <Grid item xs={12} className={styles.alertContainer}>
              <Alert variant="warning" title={t('typography.potentialSimilarOrganisationsFound')} />
            </Grid>
          )}

          <>
            <Grid item xs={12} className={styles.sectionsContainer}>
              <Typography variant="subtitle1">
                {similarOrganisations?.length}{' '}
                {t(
                  similarOrganisations?.length === 1
                    ? 'typography.similarOrganisationsFound'
                    : 'typography.similarOrganisationsFoundPlural'
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} className={styles.sectionsListContainer}>
              {similarOrganisations?.map(({ id, name, address, organisationTypes }) => (
                <Grid container className={styles.sectionContainer} key={id}>
                  <Grid xs={5}>
                    <span>{name}</span>
                  </Grid>
                  <Grid xs={5}>
                    <span>{`${renderAddressString(address)}`}</span>
                  </Grid>
                  <Grid xs={2}>
                    {organisationTypes?.map(organisationType => (
                      <>
                        <Badge variant="informative" icon="categories">
                          {t(`organisationTypes.${organisationType}`)}
                        </Badge>
                        <Spacer size={4} />
                      </>
                    ))}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>

          {!!similarOrganisations?.length && !isApproved && (
            <>
              <Grid item xs={12} className={styles.sectionsInputContainer}>
                <FormField
                  label={t('form.similarOrganisations.duplicateOrganisationAction')}
                  status={errors.action && touched.action ? 'danger' : 'default'}
                  statusMessage={errors.action}
                  data-testid="selectAction"
                >
                  <Select
                    placeholder={t('form.similarOrganisations.selectAction')}
                    searchable={false}
                    options={options}
                    onChange={({ value }) => {
                      if (value === values.action) {
                        setValues({ ...values, action: value })
                      } else {
                        setValues({
                          ...values,
                          action: value as SimilarOrganisationActionValues,
                          organisationId: ''
                        })
                      }
                    }}
                  />
                </FormField>
              </Grid>

              {showParentSelect && (
                <Grid item xs={12} className={styles.sectionsInputContainer}>
                  <FormField
                    label={t('form.relatedOrganisations.selectParent')}
                    status={errors.organisationId && touched.organisationId ? 'danger' : 'default'}
                    statusMessage={errors.organisationId}
                    data-testid="selectParent"
                  >
                    <Select
                      placeholder={t('form.relatedOrganisations.selectParent')}
                      searchable={false}
                      options={organisationOptions}
                      defaultValue={organisationOptions.find(({ value }) => values.organisationId === value)}
                      onChange={event =>
                        setValues({
                          ...values,
                          organisationId: event.value,
                          organisation: similarOrganisations.find(({ id }) => id === event.value)
                        })
                      }
                    />
                  </FormField>
                </Grid>
              )}

              {showMergeSelect && (
                <Grid item xs={12} className={styles.sectionsInputContainer}>
                  <FormField
                    label={t('form.similarOrganisations.selectMergeExistingOrganisation')}
                    status={errors.organisationId && touched.organisationId ? 'danger' : 'default'}
                    statusMessage={errors.organisationId}
                    data-testid="selectMergeExistingOrganisation"
                  >
                    <Select
                      placeholder={t('form.similarOrganisations.selectMergeExistingOrganisation')}
                      searchable={false}
                      options={organisationOptions}
                      defaultValue={organisationOptions.find(({ value }) => values.organisationId === value)}
                      onChange={event =>
                        setValues({
                          ...values,
                          organisationId: event.value,
                          organisation: similarOrganisations.find(({ id }) => id === event.value)
                        })
                      }
                    />
                  </FormField>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  )
}
