import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { useAgentVerificationDecisionContext } from '../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'
import { AgentVerificationModal } from '../../AgentVerificationDetails.types'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import { AgentVerificationStatus } from '@percent/admin-dashboard/api/types'
import { environments } from '@percent/admin-dashboard/constants/environments'
import { environment } from '@percent/admin-dashboard/config'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import styles from './AgentVerificationDecision.module.scss'
import { AgentVerificationApproveModalContainer } from './agentVerificationApproveModal/AgentVerificationApproveModalContainer'
import { AgentVerificationRejectModalContainer } from './agentVerificationRejectModal/AgentVerificationRejectModalContainer'
import { AgentVerificationDecisionProps } from './AgentVerificationDecision.types'
import { AgentVerificationApproveEmailModal } from './agentVerificationApproveEmailModal/AgentVerificationApproveEmailModal'
import { Alert, CardDashboard, Tooltip, Button, Tabs } from '@percent/lemonade'
import { AgentVerificationUpdateStatusModal } from './agentVerificationUpdateStatusModal/AgentVerificationUpdateStatusModal'

export function AgentVerificationDecision({
  agentFullName,
  organisationName,
  isLoading,
  errorMessage,
  aproveEmail,
  refresh,
  agentStatus,
  fraudFlagsLength,
  allowedOrganisations,
  partnerName,
  globalFlags,
  isStatusDynamic,
  activeTab,
  setActiveTab
}: Readonly<AgentVerificationDecisionProps>) {
  const { t } = useTranslation()
  const { setOpenedModal } = useAgentVerificationDecisionContext()
  const { id } = useParams<{ id: string }>()
  const location = useLocation()
  const {
    agentVerificationManualFradulentRejectionFeatureFlag,
    domainVerificationFeatureFlag,
    dynamicAgentVerificationFeatureFlag
  } = useFeatureFlag()

  useEffect(() => {
    if (location) {
      setActiveTab(`${location.pathname}`)
    }
  }, [location, setActiveTab])

  const triggerEmailVerificationButton = () => {
    aproveEmail({ id })
    setOpenedModal(AgentVerificationModal.APPROVE_EMAIL)
  }

  return (
    <>
      <CardDashboard className={styles.decisionContainer}>
        {agentVerificationManualFradulentRejectionFeatureFlag && fraudFlagsLength > 0 && (
          <div className={styles.fraudAlertWrapper}>
            <Alert title={t('typography.flaggedAsSuspicious', { count: fraudFlagsLength })} variant="info" />
          </div>
        )}

        {domainVerificationFeatureFlag && globalFlags && globalFlags.length > 0 && (
          <div className={styles.fraudAlertWrapper} data-testid="domain-verification-global-flags">
            <Alert variant="warning" title={t('typography.globalFlagsFound')}>
              <ul className={styles.globalFlagsList}>
                {globalFlags?.map(flag => (
                  <Tooltip content={t(`domainVerification.flag.${flag}.description`)} key={flag}>
                    <li>{t(`domainVerification.flag.${flag}`)}</li>
                  </Tooltip>
                ))}
              </ul>
            </Alert>
          </div>
        )}

        <div className={styles.contentWrapper}>
          <div className={styles.organisationInfoWrapper}>
            <span className={styles.text}>{agentFullName}</span>
            <span className={styles.subtext}>{organisationName}</span>
          </div>
          {dynamicAgentVerificationFeatureFlag &&
            isStatusDynamic &&
            (agentStatus === AgentVerificationStatus.APPROVED || agentStatus === AgentVerificationStatus.REJECTED) && (
              <Button variant="secondary" onPress={() => setOpenedModal(AgentVerificationModal.UPDATE_STATUS)}>
                {t('dialog.updateAvStatus.title')}
              </Button>
            )}
          {(agentStatus === AgentVerificationStatus.PENDING ||
            agentStatus === AgentVerificationStatus.PENDING_REVIEW ||
            agentStatus === 'pending_user_verification') && (
            <div className={styles.buttonWrapper}>
              {environment === environments.SANDBOX && (
                <PercentButton
                  title={t('button.triggerEmailVerification')}
                  onClick={triggerEmailVerificationButton}
                  className={styles.triggerEmailVerificationButton}
                />
              )}
              {agentStatus !== 'pending_user_verification' && (
                <>
                  <PercentButton
                    title={t('button.reject')}
                    onClick={() => setOpenedModal(AgentVerificationModal.REJECT)}
                    className={styles.rejectButton}
                    testId="open-rejection-modal"
                  />
                  <PercentButton
                    title={t('button.approve')}
                    onClick={() => setOpenedModal(AgentVerificationModal.APPROVE)}
                    className={styles.approveButton}
                  />
                </>
              )}
            </div>
          )}
        </div>
        <Tabs
          activeTab={activeTab}
          onChangeTab={setActiveTab}
          items={[
            { key: `/agent-verification/${id}`, label: t('typography.applicationDetails') },
            { key: `/agent-verification/${id}/timeline`, label: t('typography.applicationTimeline') }
          ]}
        />
      </CardDashboard>

      <AgentVerificationApproveModalContainer
        agentFullName={agentFullName}
        organisationName={organisationName}
        refresh={refresh}
      />
      <AgentVerificationRejectModalContainer
        agentFullName={agentFullName}
        organisationName={organisationName}
        refresh={refresh}
        allowedOrganisations={allowedOrganisations}
        partnerName={partnerName}
      />
      <AgentVerificationApproveEmailModal isLoading={isLoading} errorMessage={errorMessage} refresh={refresh} />
      {dynamicAgentVerificationFeatureFlag && (
        <AgentVerificationUpdateStatusModal
          agentFullName={agentFullName}
          organisationName={organisationName}
          agentVerificationStatus={agentStatus}
          refresh={refresh}
          allowedOrganisations={allowedOrganisations}
          partnerName={partnerName}
        />
      )}
    </>
  )
}
