import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Dialog, Loader } from '@percent/admin-dashboard/common/components'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistryModalProps } from './RegistryModal.types'
import styles from './RegistryModal.module.scss'
import { sanitizeStringToRegex } from '@percent/utility'
import { Button, Checkbox, FormField, Select, Spacer, TextInput } from '@percent/lemonade'
import { ActionsDialogAction } from '@percent/admin-dashboard/common/components/dialog/actionDialog/ActionDialog'
import { PartnerName, RegistriesType } from '@percent/admin-dashboard/api/types'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function RegistryModal({
  isOpened,
  setIsOpened,
  selectedRegistryData,
  nonprofitName,
  validationCountryCode,
  website,
  city,
  regexRegistryId,
  setSelectedRegistryData,
  organisationTypes,
  refreshRelatedOrganisations,
  refreshSimilarOrganisations,
  countrySpecificData,
  setCountrySpecificData,
  partnerName
}: RegistryModalProps) {
  const { t } = useTranslation()
  const {
    registriesSupportedTypesFeatureFlag,
    countrySpecificDataFeatureFlag,
    similarOrganisationsIndianNonprofitsFeatureFlag
  } = useFeatureFlag()

  const { adminService } = useServices()
  const [success, setSuccess] = useState<boolean>(false)

  const isIndianValidationRequest = validationCountryCode === 'IND'
  const queryParams = {
    countryCode: validationCountryCode,
    pageSize: undefined,
    supportedTypes: registriesSupportedTypesFeatureFlag ? organisationTypes : undefined
  }

  const [{ dataOrNull, isLoading, errorMessageOrNull }, { query }] = useQueryList(
    adminService.getRegistries,
    queryParams
  )

  useEffect(() => {
    if (registriesSupportedTypesFeatureFlag) {
      query(queryParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registriesSupportedTypesFeatureFlag])

  const mapRegistries = (registries?: RegistriesType[] | null) => {
    return registries
      ? registries?.map(registry => {
          return {
            label: registry.englishName || registry.name,
            value: registry.id
          }
        })
      : []
  }

  const options = mapRegistries(dataOrNull)

  const isNonprofitOrganisation = organisationTypes?.includes(OrganisationTypes.Nonprofit)

  const isOnlyEducationOrganisation =
    organisationTypes?.length === 1 && organisationTypes.includes(OrganisationTypes.Education)

  const isIndianNonprofit = isIndianValidationRequest && isNonprofitOrganisation

  const { values, errors, handleBlur, setFieldValue, isValid, dirty, handleChange, touched, handleSubmit } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      registryId: selectedRegistryData?.registry?.id,
      organisationRegistryId: selectedRegistryData?.organisationRegistryId,
      regex: selectedRegistryData?.registry?.regex,
      registryInformationNotFound: selectedRegistryData?.registryInformationNotFound || false,
      countrySpecificData: {
        ...(countrySpecificDataFeatureFlag && isIndianNonprofit ? { fcra: countrySpecificData?.fcra ?? '' } : {})
      }
    },
    validationSchema: () =>
      yup.lazy(value => {
        const newRegex = value.registryId
          ? dataOrNull?.find(registry => registry.id === value?.registryId)?.registryIdFormatRegex
          : regexRegistryId

        return yup.object().shape({
          registryInformationNotFound: yup.boolean(),
          registryId: yup
            .string()
            .when('registryInformationNotFound', { is: false, then: yup.string().required('Required') }),
          organisationRegistryId: yup.string().when('registryInformationNotFound', {
            is: false,
            then: yup
              .string()
              .matches(sanitizeStringToRegex(newRegex || ''), t('errorMessage.wrongFormatRegistryId'))
              .required('Required')
          }),
          countrySpecificData: yup
            .object()
            .optional()
            .shape({
              fcra: yup.string().matches(/^\d{8,10}$/, t('errorMessage.wrongFormatFCRA'))
            })
        })
      }),
    onSubmit: submitValues => {
      const { registryId, organisationRegistryId, registryInformationNotFound } = submitValues

      const registry = dataOrNull?.find(({ id }) => id === registryId)

      setSelectedRegistryData({
        registry: {
          id: registry?.id,
          name: registry?.name,
          englishName: registry?.englishName,
          regex: registry?.registryIdFormatRegex
        },
        organisationRegistryId,
        registryInformationNotFound
      })

      countrySpecificDataFeatureFlag &&
        setCountrySpecificData?.(previousValue =>
          previousValue
            ? {
                ...previousValue,
                ...submitValues.countrySpecificData
              }
            : submitValues.countrySpecificData
        )
      setSuccess(true)

      if (registryInformationNotFound || (isIndianNonprofit && similarOrganisationsIndianNonprofitsFeatureFlag)) {
        refreshSimilarOrganisations({
          registry: registry?.id,
          registryId: organisationRegistryId,
          countryCode: validationCountryCode,
          name: nonprofitName,
          city,
          website
        })
      }

      if (
        registry &&
        organisationRegistryId &&
        !registryInformationNotFound &&
        !(isIndianNonprofit && similarOrganisationsIndianNonprofitsFeatureFlag)
      ) {
        refreshRelatedOrganisations({ registry: registry.id, registryId: organisationRegistryId })
      }
    }
  })

  const handleClose = () => {
    setIsOpened(false)
    setSuccess(false)
  }

  const isMicrosoftSocialImpact =
    partnerName?.toLowerCase() === PartnerName.MICROSOFT &&
    organisationTypes?.length === 1 &&
    organisationTypes.includes(OrganisationTypes.SocialImpact)

  return (
    <Dialog openModal={isOpened} onClose={handleClose} withoutHeader={success} headerTitle={t('dialog.reviewRegistry')}>
      {isLoading ? (
        <Loader />
      ) : errorMessageOrNull ? (
        <ActionsDialogAction
          handleOnClose={() => setIsOpened(false)}
          description={errorMessageOrNull}
          title={t('dialog.somethingWentWrong')}
          buttonTitle={t('button.done')}
          successIcon={false}
        />
      ) : success ? (
        <ActionsDialogAction
          handleOnClose={() => setIsOpened(false)}
          description={`${nonprofitName}${t('dialog.registryInfoUpdatedDescription')}`}
          title={t('dialog.registryInfoUpdated')}
          buttonTitle={t('button.done')}
          successIcon
        />
      ) : (
        <>
          <Grid item xs={12} className={styles.modalBody}>
            <Typography>
              {t('dialog.reviewRegistryDescription')} <span className={styles.organisationName}>{nonprofitName}</span>:
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} className={styles.form}>
              <FormField
                label={t('dialog.approveValdiationRequest.selectRegistry')}
                status={errors.registryId && touched.registryId ? 'danger' : 'default'}
                statusMessage={errors.registryId}
                data-testid="selectRegistry"
                disabled={!!values.registryInformationNotFound}
              >
                <Select
                  placeholder={t('dialog.approveValdiationRequest.selectRegistry')}
                  searchable={false}
                  options={options}
                  disabled={!!values.registryInformationNotFound}
                  defaultValue={options.find(({ value }) => value === values?.registryId)}
                  onChange={event => {
                    setFieldValue('registryId', event.value)
                  }}
                />
              </FormField>
            </Grid>
            <Grid item xs={12} className={styles.form} data-testid="registry-id-input">
              <FormField
                label={t('dialog.approveValdiationRequest.registryId')}
                status={errors.organisationRegistryId ? 'danger' : 'default'}
                statusMessage={errors.organisationRegistryId}
                data-testid="organisationRegistryId"
                disabled={!!values.registryInformationNotFound}
              >
                <TextInput
                  name="organisationRegistryId"
                  value={values.organisationRegistryId}
                  placeholder={t('dialog.approveValdiationRequest.registryId')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormField>

              {countrySpecificDataFeatureFlag && isIndianNonprofit && (
                <>
                  <Spacer size={4} axis="vertical" />
                  <FormField
                    label={`${t('dialog.approveValdiationRequest.fcraId')} (${t('form.optional')})`}
                    status={errors.countrySpecificData?.fcra ? 'danger' : 'default'}
                    statusMessage={errors.countrySpecificData?.fcra}
                    data-testid="fcra"
                    disabled={!!values.registryInformationNotFound}
                  >
                    <TextInput
                      name="countrySpecificData.fcra"
                      value={values.countrySpecificData.fcra}
                      placeholder={t('dialog.approveValdiationRequest.fcraId')}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormField>
                </>
              )}
              {(isOnlyEducationOrganisation || isMicrosoftSocialImpact) && (
                <>
                  <Spacer size={4} axis="vertical" />
                  <FormField
                    status={errors.registryInformationNotFound ? 'danger' : 'default'}
                    statusMessage={errors.registryInformationNotFound}
                    data-testid="registryInformationNotFound"
                  >
                    <Checkbox
                      name="registryInformationNotFound"
                      value={values.registryInformationNotFound?.toString()}
                      onBlur={handleBlur}
                      variant="default"
                      onChange={handleChange}
                      active={values.registryInformationNotFound}
                      label={t('dialog.approveValdiationRequest.registryInformationNotFound')}
                    />
                  </FormField>
                </>
              )}
            </Grid>

            <Grid item xs={12} className={styles.modalBody}>
              <Typography>{t('dialog.reviewRegistryDescriptionFooter')}</Typography>
            </Grid>
            <Grid item xs={12} className={styles.dialogFooter}>
              <Button
                disabled={!(dirty && isValid)}
                type="submit"
                variant="primary"
                data-testid="btn-update-information"
                loading={isLoading}
              >
                {t('button.updateInformation')}
              </Button>
              <Spacer size={4} />
              <Button
                variant="secondary"
                data-testid="btn-cancel-validiation"
                onPress={() => {
                  setIsOpened(false)
                }}
              >
                {t('button.cancel')}
              </Button>
            </Grid>
          </form>
        </>
      )}
    </Dialog>
  )
}
