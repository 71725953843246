import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { MyAccount } from '../../myAccount/MyAccount'
import { ComplianceContainer } from '../../compliance/ComplianceContainer'
import { AgentVerificationDetailsContainer } from '../../agentVerification/agentVerificationDetails/AgentVerificationDetailsContainer'
import { DonationRequestDetailContainer } from '../../donationRequests/donationRequestDetail/DonationRequestDetailContainer'
import { OrganizationsContainer } from '../../organizations/OrganizationsContainer'
import { OrganizationsDetail } from '../../organizations/organizationsDetail/OrganizationsDetail'
import { NonprofitDetail } from '../../validations/nonprofitDetail/NonprofitDetail'
import { MilestonesContainer } from '../../organizations/organizationsDetail/milestones/MilestonesContainer'
import { DonationMatchRequestDetailsContainer } from '../../donationMatchRequest/donationMatchRequest/DonationMatchRequestDetailsContainer'
import { AutoRejectionListContainer } from '../../autoRejectionList/AutoRejectionListContainer'
import { ValidationSubmissionsContainer } from '../../validationSubmissions/ValidationSubmissionsContainer'
import { ValidationSubmissionDetailsContainer } from '../../validationSubmissions/validationSubmissionDetails/ValidationSubmissionDetailsContainer'

import { BankDetailsView } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/BankDetailsView'
import { ClaimsContainer } from '@percent/admin-dashboard/app/claims/ClaimsContainer'
import { ValidationsContainer } from '@percent/admin-dashboard/app/validations/ValidationsContainer'
import { PartnerDetail } from '@percent/admin-dashboard/app/partner/partnerDetail/PartnerDetail'
import { PartnerListTable } from '@percent/admin-dashboard/app/partner/partnerListTable/PartnerListTable'
import { AgentVerificationContainer } from '@percent/admin-dashboard/app/agentVerification/AgentVerificationContainer'
import { ActivityCheckContainer } from '@percent/admin-dashboard/app/activityCheck/ActivityCheckContainer'
import { ActivityCheckDetailView } from '@percent/admin-dashboard/app/activityCheck/ActivityCheckDetail/ActivityCheckDetailView'
import { EligibilityContainer } from '@percent/admin-dashboard/app/eligibility/EligibilityContainer'
import { EligibilityDetailsContainer } from '@percent/admin-dashboard/app/eligibility/eligibilityDetail/EligibilityDetailsContainer'
import { RegistriesContainer } from '@percent/admin-dashboard/app/registries/RegistriesContainer'
import { BankDetailsContainer } from '@percent/admin-dashboard/app/bankDetails/bankDetailsContainer/BankDetailsContainer'
import { DisbursementsContainer } from '@percent/admin-dashboard/app/disbursements/DisbursementsContainer'
import { DisbursementDetailsContainer } from '@percent/admin-dashboard/app/disbursements/disbursementDetails/DisbursementDetailsContainer'
import { DonationRequestsContainer } from '@percent/admin-dashboard/app/donationRequests/DonationRequestsContainer'
import { DonationsContainer } from '@percent/admin-dashboard/app/donations/DonationsContainer'
import { DonationMatchRequestsListContainer } from '@percent/admin-dashboard/app/donationMatchRequest/donationMatchRequestsList/DonationMatchRequestsListContainer'
import { FeatureRoute } from './FeatureRoute'
import { DEFAULT_ROUTE } from './default-route'

export function Routes() {
  return (
    <Switch>
      <Route path="/my-account" exact component={MyAccount} key="MyAccount" />,
      <Route path="/organizations" exact component={OrganizationsContainer} key="OrganizationsContainer" />,
      <Route path="/organizations/:id" component={OrganizationsDetail} key="OrganizationsDetail" />,
      <Route
        path="/nonprofits/:id"
        exact
        key="RedirectRouteToOrganization"
        render={({ match }) => (
          <Redirect to={{ pathname: `/organizations/${match?.params?.id}`, data: 'nonprofits' }} />
        )}
      />
      <Route path="/claims" exact component={ClaimsContainer} key="ClaimsContainer" />,
      <Route path="/validations" exact component={ValidationsContainer} key="ValidationsContainer" />,
      <Route path="/partners" exact component={PartnerListTable} key="PartnerListTable" />,
      <Route path="/partners/partner/:id" component={PartnerDetail} key="PartnerDetail" />,
      <Route path="/milestones" component={MilestonesContainer} key="MilestonesContainer" />
      <Route path="/validations/:id" component={NonprofitDetail} key="NonprofitDetail" />
      <Route path="/eligibility" exact component={EligibilityContainer} key="EligibilityContainer" />
      <Route path="/eligibility/:id" component={EligibilityDetailsContainer} key="EligibilityDetailsContainer" />
      <Route path="/compliance" exact component={ComplianceContainer} key="ComplianceContainer" />
      <Route path="/agent-verification" exact component={AgentVerificationContainer} key="AgentVerificationContainer" />
      <Route
        path="/agent-verification/:id"
        exact
        component={AgentVerificationDetailsContainer}
        key="AgentVerificationDetailsContainer"
      />
      <Route path="/activity/:id" exact component={ActivityCheckDetailView} key="ActivityCheckDetailView" />
      <Route path="/activity" exact component={ActivityCheckContainer} key="ActivityCheckContainer" />
      <FeatureRoute
        flag="registriesFeatureFlag"
        path="/registries"
        component={RegistriesContainer}
        key="RegistriesContainer"
      />
      <FeatureRoute
        flag="bankDetailsReadFeatureFlag"
        path="/bank-details-reviews/:id"
        exact
        component={BankDetailsView}
        key="BankDetailsView"
      />
      <FeatureRoute
        flag="bankDetailsReadFeatureFlag"
        path="/bank-details-reviews"
        exact
        component={BankDetailsContainer}
        key="BankDetailsContainer"
      />
      <FeatureRoute
        flag="disbursementsFeatureFlag"
        path="/disbursements"
        exact
        component={DisbursementsContainer}
        key="DisbursementsContainer"
      />
      <FeatureRoute
        flag="disbursementsFeatureFlag"
        path="/disbursements/:id"
        component={DisbursementDetailsContainer}
        key="DisbursementDetailsContainer"
      />
      <FeatureRoute
        flag="donationsFeatureFlag"
        path="/donations"
        component={DonationsContainer}
        key="DonationsContainer"
      />
      <FeatureRoute
        flag="donationMatchRequestsFeatureFlag"
        path="/donation-match-requests"
        exact
        component={DonationMatchRequestsListContainer}
        key="DonationMatchRequestsListContainer"
      />
      <FeatureRoute
        flag="donationMatchRequestsFeatureFlag"
        path="/donation-match-requests/:donationMatchRequestId"
        component={DonationMatchRequestDetailsContainer}
        key="DonationMatchRequestDetailsContainer"
      />
      <FeatureRoute
        path="/donation-requests"
        exact
        component={DonationRequestsContainer}
        key="DonationRequestsContainer"
        flag="donationRequestsFeatureFlag"
      />
      <FeatureRoute
        flag="donationRequestsFeatureFlag"
        path="/donation-requests/:id"
        component={DonationRequestDetailContainer}
        key="DonationRequestDetailContainer"
      />
      <FeatureRoute
        flag="autoRejectionListFeatureFlag"
        path="/auto-rejection-list"
        exact
        component={AutoRejectionListContainer}
        key="AutoRejectionListContainer"
      />
      <FeatureRoute
        flag="validationSubmissionTabFeatureFlag"
        path="/validation-submissions"
        exact
        component={ValidationSubmissionsContainer}
        key="ValidationSubmissionsContainer"
      />
      <FeatureRoute
        flag="validationSubmissionTabFeatureFlag"
        path="/validation-submissions/:id"
        component={ValidationSubmissionDetailsContainer}
        key="ValidationSubmissionDetailsContainer"
      />
      <Redirect to={DEFAULT_ROUTE} />
    </Switch>
  )
}
