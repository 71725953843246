import React, { EventHandler, KeyboardEvent, useCallback, useMemo, useState } from 'react'
import { PopperPlacementType, TableCell, TableRow } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TableColumn, TableSort } from '@percent/admin-dashboard/common/components/table/Table.types'
import { ErrorView, SearchAndDateFilter, Table, TimeLeft } from '@percent/admin-dashboard/common/components'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { DateRangePopper } from '@percent/admin-dashboard/common/components/dateRange/dateRangePopper/DateRangePopper'
import { MonitoringList } from '@percent/admin-dashboard/api/types'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import styles from './ComplianceTable.module.scss'
import { ComplianceStatusFilter, ComplianceTableProps } from './ComplianceTable.types'

export function ComplianceTable({
  totalResults,
  subscriptions,
  isLoading,
  previousPage,
  nextPage,
  queryParams,
  setQueryParams,
  errorMessage
}: ComplianceTableProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()
  const [placement, setPlacement] = useState<PopperPlacementType>('bottom-end')

  const tabs = [t('table.pending'), t('table.live'), t('table.invalid')]

  const handleRequestSort = () =>
    setQueryParams({ direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC', sort: 'name' })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setQueryParams({ status: ComplianceStatusFilter[newValue], sort: undefined, direction: undefined })
  }

  const organizationRowClick = (row: MonitoringList) => {
    push(`/organizations/${row.organisationId}/compliance`)
  }

  const handleClick = useCallback(
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setIsDatePickerOpened(prev => !prev)
      setPlacement(newPlacement)
    },
    [anchorEl]
  )

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value
        })
      }
    },
    [setQueryParams]
  )

  const handleClearDate = useCallback(
    () => setQueryParams({ startDate: undefined, endDate: undefined }),
    [setQueryParams]
  )

  const filters = useMemo(
    () => (
      <SearchAndDateFilter
        handleClick={handleClick}
        handleKeyPress={handleKeyPress}
        handleClearDate={handleClearDate}
        maxLength={255}
        searchPhrase={queryParams.query ?? ''}
        placeholder={t('form.monitoringSearchPlaceholder')}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClick, handleClearDate, handleKeyPress, queryParams.query, t]
  )

  const columns = useMemo(() => {
    const commonColumns: Array<TableColumn> = [
      { id: 'country', isSortable: false },
      { id: 'organization', isSortable: true },
      { id: 'partner', isSortable: false }
    ]

    if (queryParams.status === 'pending') {
      commonColumns.push(
        { id: 'requestDate', isSortable: false, props: { width: '100px' } },
        { id: 'timeLeft', isSortable: false }
      )
    }

    return commonColumns
  }, [queryParams.status])

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={subscriptions}
        previousPage={previousPage}
        nextPage={nextPage}
        value={
          ComplianceStatusFilter[queryParams.status as keyof typeof ComplianceStatusFilter] ??
          ComplianceStatusFilter.pending
        }
        handleChange={handleTabChange}
        handleRequestSort={handleRequestSort}
        order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'asc'}
        orderBy="nonprofit"
        columns={columns}
        tabs={tabs}
        emptyTableText={t('table.emptyMonitoring')}
        filtersContent={filters}
      >
        {subscriptions?.map(row => (
          <TableRow key={row.id} onClick={() => organizationRowClick(row)} className={row.organisationId && styles.row}>
            <CountryCodeFlag countryCode={row.countryCode} />
            <TableCell>{row.organisationName}</TableCell>
            <TableCell className={styles.date}>{row.partnerName}</TableCell>
            {queryParams.status === 'pending' && (
              <TableCell
                align="right"
                className={styles.date}
                style={{ padding: '16px 50px 16px 0', textAlign: 'left' }}
              >
                {getDate(row.createdAt)}
              </TableCell>
            )}
            {queryParams.status === 'pending' && (
              <TableCell className={styles.date}>
                <TimeLeft requestedDate={row.createdAt} />
              </TableCell>
            )}
            <TableCell align="right" className={styles.rowIconForward} style={{ padding: '0' }}>
              <DropdownSVG className={styles.arrow} />
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <DateRangePopper
        open={isDatePickerOpened}
        anchorEl={anchorEl}
        placement={placement}
        setOpen={setIsDatePickerOpened}
        setAnchorEl={setAnchorEl}
        setPlacement={setPlacement}
        setQueryParams={setQueryParams}
      />
    </>
  )
}
