import { Paper, Grid, Typography, capitalize } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useNonprofitsData } from '../../Layout/nonprofitsContext/useNonprofitsData/useNonprofitsData'
import { useBreadcrumbsData } from '../../Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'

import { ErrorView, Status } from '@percent/admin-dashboard/common/components'
import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { getRegion } from '@percent/admin-dashboard/common/utility/getRegion'
import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { ReactComponent as ExpandLogo } from '@percent/admin-dashboard/common/assets/images/expand.svg'
import { ReactComponent as PencilSVG } from '@percent/admin-dashboard/common/assets/images/pencil.svg'
import { colors } from '@percent/admin-dashboard/identity'
import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'
import { Documents } from '@percent/admin-dashboard/common/components/documents/Documents'
import { NonProfitDetailHeader } from './header/NonProfitDetailHeader'
import styles from './NonprofitDetail.module.scss'
import { ApproveModal, RejectModal } from './dialogs'
import { UpdateWebsiteModal } from './dialogs/updateWebsiteModal/UpdateWebsiteModal'
import { CreateRegistryProps } from '@percent/admin-dashboard/api/actions/registries/registries.types'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { sanitizeStringToRegex } from '@percent/utility'
import { ValidationDocument, ValidationSubmissionDocument } from '@percent/admin-dashboard/api/types'
import { NonProfitDetailListItem } from './nonProfitDetailListItem/NonProfitDetailListItem'
import { OrganisationTypeSection } from './organisationType/OrganisationTypeSection'
import { RegistryModal } from './dialogs/registryModal/RegistryModal'
import { CountrySpecificData, SelectedRegistryData } from './dialogs/registryModal/RegistryModal.types'
import { RelatedOrganisations } from './relatedOrganisations/RelatedOrganisations'
import {
  RelationshipDropdownValues,
  RelationshipFormValues,
  RelationshipValues
} from './relatedOrganisations/RelatedOrganisations.types'
import { RelationshipBadge } from './relationshipBadge/RelationshipBadge'
import { validationOrganisationHasRelationships } from './utils/validationOrganisationHasRelationships'
import { validationOrganisationIsParent } from './utils/validationOrganisationIsParent'
import { FlagBadge } from './flagBadge/FlagBadge'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { renderAddressString } from '@percent/admin-dashboard/common/utility/renderAddressString'
import { SimilarOrganisations } from './similarOrganisations/SimilarOrganisations'
import {
  SimilarOrganisationActionValues,
  SimilarOrganisationFormValues
} from './similarOrganisations/SimilarOrganisations.types'
import { SubTagsModal } from './dialogs/subTagsModal/SubTagsModal'
import { Badge, Spacer } from '@percent/lemonade'
import { SubTagModalFormValues } from './dialogs/subTagsModal/SubTagsModal.types'
import { sortEducationSubtags } from '@percent/admin-dashboard/common/utility/sortEducationSubtags'
import { useActivityTags } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags'
import { DecoratedActivitySubTag } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function NonprofitDetail({ match }: { match: { params: { id: string } } }) {
  const { t } = useTranslation()
  const {
    countrySpecificDataFeatureFlag,
    registriesSupportedTypesFeatureFlag,
    similarOrganisationsIndianNonprofitsFeatureFlag
  } = useFeatureFlag()
  const { setPath } = useBreadcrumbsData()
  const { adminService, registriesService } = useServices()
  const { validation, setValidation } = useNonprofitsData()
  const [isApproveModalOpened, setIsApproveModalOpened] = useState(false)
  const [isRejectModalOpened, setIsRejectModalOpened] = useState(false)
  const [isSubTagsModalOpened, setIsSubTagsModalOpened] = useState(false)
  const [isWebsiteModalOpened, setWebsiteModalOpened] = useState(false)
  const [isRegistryModalOpened, setRegistryModalOpened] = useState(false)
  const [registryData, setRegistryData] = useState<CreateRegistryProps | null>(null)
  const [registryDataError, setRegistryDataError] = useState('')
  const [selectedRegistryData, setSelectedRegistryData] = useState<SelectedRegistryData>(null)
  const [documents, setDocuments] = useState<ValidationDocument[] | ValidationSubmissionDocument[]>([])
  const [subTags, setSubTags] = useState<DecoratedActivitySubTag[]>([])
  const [countrySpecificData, setCountrySpecificData] = useState<CountrySpecificData>(null)

  const toast = useToast()

  const { activityTags } = useActivityTags({ filterByEducation: true })

  const { id } = match.params

  // QUERIES

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(adminService.getValidationRequest, { id })

  const [
    { dataOrNull: relatedOrganisations, isLoading: isLoadingRelatedOrganisations },
    { query: refreshRelatedOrganisations }
  ] = useQueryList(
    adminService.getRelatedOrganisations,
    {
      registry: selectedRegistryData?.registry.id,
      registryId: selectedRegistryData?.organisationRegistryId,
      relationshipType: 'branch'
    },
    false
  )

  const [
    { dataOrNull: similarOrganisations, isLoading: isLoadingSimilarOrganisations },
    { query: refreshSimilarOrganisations }
  ] = useQueryList(
    adminService.getSimilarOrganisations,
    {
      registry: selectedRegistryData?.registry.id,
      registryId: selectedRegistryData?.organisationRegistryId,
      countryCode: validation?.countryCode,
      name: validation?.name,
      city: validation?.city,
      website: validation?.website,
      address: renderAddressString({
        addressLine1: validation?.addressLine1,
        addressLine2: validation?.addressLine2,
        city: validation?.city,
        postal: validation?.postal,
        state: validation?.state
      })
    },
    false
  )

  const [
    { dataOrNull: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments },
    { query: refreshValidationSubmissionDocuments }
  ] = useQueryList(
    adminService.getValidationSubmissionDocuments,
    {
      validationSubmissionId: validation?.validationSubmissionId
    },
    false
  )

  // FORMS

  const organisationTypeForm = useFormik<{ organisationTypes: OrganisationTypes[] }>({
    initialValues: {
      organisationTypes: []
    },
    validationSchema: yup.object().shape({
      organisationTypes: yup.array().of(yup.string()).min(1)
    }),
    onSubmit: () => {}
  })

  const subTagsForm = useFormik<SubTagModalFormValues>({
    initialValues: {
      subTags: [],
      insufficientInformation: false,
      notEducation: false
    },
    validationSchema: () => {
      if (isOnlyEducationOrganisation) {
        return yup.object().shape({
          subTags: yup.array().when(['insufficientInformation', 'notEducation'], {
            is: false,
            then: yup.array().of(yup.string()).min(1),
            otherwise: yup.array().of(yup.string().max(0))
          }),
          insufficientInformation: yup.boolean(),
          notEducation: yup.boolean()
        })
      }

      return yup.object().shape({})
    },

    onSubmit: () => {}
  })

  const relationshipForm = useFormik<RelationshipFormValues>({
    initialValues: {
      type: '',
      organisationId: ''
    },
    validationSchema: () => {
      if (relatedOrganisations?.length) {
        return yup.object().shape({
          type: yup
            .string()
            .oneOf([
              RelationshipDropdownValues.MERGE,
              RelationshipDropdownValues.BRANCH_CHILD,
              RelationshipDropdownValues.BRANCH_PARENT,
              RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD,
              RelationshipDropdownValues.FISCAL_SPONSORSHIP_PARENT
            ])
            .required('Required Type'),
          organisationId: yup.string().when(['type'], {
            is: (type: RelationshipDropdownValues) =>
              [
                RelationshipDropdownValues.MERGE,
                RelationshipDropdownValues.BRANCH_CHILD,
                RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD
              ].includes(type),
            then: schema => schema.required('Required')
          }),
          organisation: yup.object().when(['organisationId'], {
            is: (value: string) => !!value,
            then: schema =>
              schema
                .shape({
                  relationship: yup.string(),
                  result: yup.object().shape({
                    id: yup.string().required()
                  })
                })
                .required('Required')
          })
        })
      }

      return yup.object().shape({})
    },
    onSubmit: () => {}
  })

  const similarOrganisationForm = useFormik<SimilarOrganisationFormValues>({
    initialValues: {
      action: '',
      organisationId: ''
    },
    validationSchema: () => {
      if (selectedRegistryData?.registryInformationNotFound) {
        return yup.object().shape({
          action: yup
            .string()
            .oneOf([SimilarOrganisationActionValues.IGNORE, SimilarOrganisationActionValues.MERGE])
            .required('Required'),
          organisationId: yup.string().when(['action'], {
            is: (action: SimilarOrganisationActionValues) => [SimilarOrganisationActionValues.MERGE].includes(action),
            then: schema => schema.required('Required')
          }),
          organisation: yup.object().when(['organisationId'], {
            is: (value: string) => !!value,
            then: schema =>
              schema
                .shape({
                  name: yup.string(),
                  organisationTypes: yup.array().of(yup.string()).min(1),
                  website: yup.string(),
                  countryCode: yup.string(),
                  registry: yup.string(),
                  registryId: yup.string()
                })
                .required('Required')
          })
        })
      }

      return yup.object().shape({})
    },
    onSubmit: () => {}
  })

  // HELPER FUNCTIONS

  const getRegistryData = useCallback(
    (registryId: string) =>
      registriesService
        .getRegistriesById({ id: registryId })
        .then(regData => {
          setRegistryData(regData?.data?.data)
        })
        .catch(error => {
          setRegistryDataError(APIErrorHandler(error?.response?.data?.error))
        }),
    [registriesService]
  )

  const getValidationStatus = () => {
    if (validation?.acceptedAt || (validation?.organisationId && validation?.rejectedAt)) {
      return Status.variants.APPROVED
    }

    if (validation?.rejectedAt) {
      return Status.variants.REJECTED
    }

    return Status.variants.PENDING
  }

  const name = data ? data.name : ''

  const registryIdDoesNotMatchRegistryRegex = () => {
    if (selectedRegistryData?.registryInformationNotFound) {
      return false
    }

    return (
      !!selectedRegistryData?.organisationRegistryId &&
      !sanitizeStringToRegex(registryIdRegex || '').test(selectedRegistryData?.organisationRegistryId)
    )
  }

  const disableConfirm = () => {
    if (
      (!selectedRegistryData?.registryInformationNotFound && !selectedRegistryData?.registry.id) ||
      registryIdDoesNotMatchRegistryRegex()
    ) {
      return true
    }

    if (relatedOrganisations?.length && !relationshipForm.values.type) {
      return true
    }

    if (Object.keys(relationshipForm?.errors).length) {
      return true
    }

    if (Object.keys(organisationTypeForm?.errors).length) {
      return true
    }

    if (
      selectedRegistryData?.registry.id &&
      !selectedRegistryData?.registryInformationNotFound &&
      isLoadingRelatedOrganisations
    ) {
      return true
    }

    if (similarOrganisations?.length && !similarOrganisationForm.values.action) {
      return true
    }

    if (Object.keys(similarOrganisationForm?.errors).length) {
      return true
    }

    if (selectedRegistryData?.registryInformationNotFound && isLoadingSimilarOrganisations) {
      return true
    }

    if (isOnlyEducationOrganisation) {
      if (Object.keys(subTagsForm?.errors).length) {
        return true
      }

      if (
        !subTagsForm.values.insufficientInformation &&
        !subTagsForm.values.notEducation &&
        !subTagsForm.values.subTags.length
      ) {
        return true
      }
    }

    return false
  }

  // VARIABLES

  const nonprofitName = capitalize(String(name))
  const oracleValidationLink = `oracleValidationLink.${validation?.countryCode}` as LocaleKey
  const status = getValidationStatus()
  const inputsEditable = ![Status.variants.APPROVED, Status.variants.REJECTED].includes(status)

  const registryIdRegex = selectedRegistryData?.registry?.id
    ? selectedRegistryData?.registry?.regex
    : validation?.fieldConstraints?.fields?.registryId?.regex

  const isOnlyEducationOrganisation =
    organisationTypeForm.values.organisationTypes?.length === 1 &&
    organisationTypeForm.values.organisationTypes.includes(OrganisationTypes.Education)

  const isNonprofitOrganisation = organisationTypeForm.values.organisationTypes.includes(OrganisationTypes.Nonprofit)

  const isApproved = [Status.variants.APPROVED].includes(status)
  const isRejected = [Status.variants.REJECTED].includes(status)

  const isIndianValidationRequest = validation?.countryCode === 'IND'

  const isIndianNonprofit = isIndianValidationRequest && isNonprofitOrganisation

  const hideRelatedOrganisationsSection =
    isRejected ||
    (isApproved && !validation?.organisation?.relationships?.length) ||
    (!isApproved && !selectedRegistryData?.registry?.id)

  const hideSimilarOrganisationsSection =
    isRejected ||
    isApproved ||
    (!isApproved &&
      !selectedRegistryData?.registryInformationNotFound &&
      !(isIndianNonprofit && similarOrganisationsIndianNonprofitsFeatureFlag)) ||
    (!isApproved && !similarOrganisations?.length)

  const insufficientSubTagsInformation =
    subTagsForm.values.insufficientInformation || (isApproved && validation?.organisationSubTags === null)

  // SIDE EFFECTS
  useEffect(() => {
    if (data) {
      setValidation(data)
    }

    if (validation?.organisation) {
      getRegistryData(validation?.organisation?.registry)
    }
  }, [data, getRegistryData, setValidation, validation, refreshValidationSubmissionDocuments])

  useEffect(() => {
    if (data?.validationSubmissionId) {
      refreshValidationSubmissionDocuments({ validationSubmissionId: data?.validationSubmissionId })
    }
  }, [data, refreshValidationSubmissionDocuments])

  useEffect(() => {
    if (validation?.registryId || validation?.organisation?.registry) {
      const registryName =
        registryData?.name ??
        validation.organisation?.registryData?.name ??
        validation.organisation?.registry ??
        validation.registryName

      setSelectedRegistryData({
        registry: {
          id: '',
          name: registryName,
          englishName: registryData?.englishName ?? registryName
        },
        organisationRegistryId: validation?.organisation?.registryId ?? validation?.registryId ?? '',
        registryInformationNotFound: false
      })
    }
  }, [validation, registryData])

  useEffect(() => {
    if (!registriesSupportedTypesFeatureFlag) return

    const registryName =
      registryData?.name ??
      validation?.organisation?.registryData?.name ??
      validation?.organisation?.registry ??
      validation?.registryName

    setSelectedRegistryData({
      registry: {
        id: '',
        name: registryName,
        englishName: registryData?.englishName ?? registryName
      },
      organisationRegistryId: validation?.organisation?.registryId ?? validation?.registryId ?? '',
      registryInformationNotFound: false
    })
  }, [registryData, validation, organisationTypeForm.values.organisationTypes, registriesSupportedTypesFeatureFlag])

  useEffect(() => {
    setPath(name)
  }, [setPath, name])

  useEffect(() => {
    if (
      validation?.configuration?.allowedOrganisations?.types?.[0] &&
      !organisationTypeForm.values.organisationTypes.length
    ) {
      organisationTypeForm.resetForm({
        values: { organisationTypes: validation.configuration.allowedOrganisations.types }
      })
    }
  }, [validation])

  useEffect(() => {
    if (countrySpecificDataFeatureFlag && isIndianNonprofit && validation?.organisation?.countrySpecificData) {
      setCountrySpecificData(validation?.organisation?.countrySpecificData)
    }
  }, [validation, countrySpecificDataFeatureFlag])

  useEffect(() => {
    if (validation?.organisationSubTags?.length && !subTagsForm.values.subTags.length) {
      const hasNoValidSubTags = !validation?.organisationSubTags.some(({ meetsCriteria }) => meetsCriteria === true)

      subTagsForm.resetForm({
        values: {
          insufficientInformation: false,
          subTags: !hasNoValidSubTags
            ? validation?.organisationSubTags?.map(({ id: organisationSubTagId }) => organisationSubTagId)
            : [],
          notEducation: hasNoValidSubTags
        }
      })
    }
  }, [validation])

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const documentsToFetch = validation?.documents ?? []
        const documentResponses = await Promise.all(
          documentsToFetch.map(({ url }) => adminService.getSignedUrlForDocument({ documentUrl: url }))
        )
        const updatedDocuments = documentResponses.map(response => {
          if (response.status !== 200) {
            throw new Error('Cannot fetch document.')
          }
          const { documentId, signedUrl } = response.data.data
          const oldDocument = documentsToFetch.find((doc: ValidationDocument) => doc.id === documentId)!

          return { ...oldDocument, url: signedUrl }
        }) as ValidationDocument[]

        setDocuments(updatedDocuments)
      } catch (_err) {
        toast(t('toast.documentsFetchError'), 'error')
      }
    }

    if (!validation?.validationSubmissionId) {
      fetchDocuments()
    } else {
      setDocuments(validationSubmissionDocuments ?? [])
    }
  }, [validation?.validationSubmissionId, validationSubmissionDocuments, validation?.documents, adminService, t, toast])

  useEffect(() => {
    if (isOnlyEducationOrganisation && activityTags?.length) {
      setSubTags(
        [
          ...activityTags.flatMap(({ id: parentTagId, name: parentTagName, subTags: activityTagSubTags }) =>
            activityTagSubTags
              .map(subTag => ({ ...subTag, parentTagName, parentTagId }))
              .filter(({ deprecatedAt }) => !deprecatedAt)
          )
        ].sort(sortEducationSubtags)
      )
    }
  }, [activityTags, isOnlyEducationOrganisation])

  if (registryDataError || errorMessage) {
    return <ErrorView errorMessage={errorMessage || registryDataError} />
  }

  return (
    <>
      <NonProfitDetailHeader
        nonprofitName={nonprofitName}
        status={status}
        setIsRejectModalOpened={setIsRejectModalOpened}
        setIsApproveModalOpened={setIsApproveModalOpened}
        notValidForProgram={!!validation?.rejectedAt && !!validation.organisationId}
        disableConfirm={disableConfirm()}
        isApproved={isApproved}
        isRejected={isRejected}
      />

      <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h6">{t('typography.organisationDetails')}</Typography>
        </Grid>
        <ul className={styles.detailsList}>
          <FormikProvider value={organisationTypeForm}>
            <OrganisationTypeSection status={status} validationOrganisationTypes={validation?.organisationTypes} />
          </FormikProvider>
          {isOnlyEducationOrganisation && !isRejected && (
            <NonProfitDetailListItem title={t('table.eligibityTags')}>
              {insufficientSubTagsInformation && <span>{t('typography.insufficientInformation')}</span>}
              {subTagsForm.values.notEducation && (
                <span>{t(inputsEditable ? 'typography.noValidSubtags' : 'typography.markedAsNoValidSubTags')}</span>
              )}
              {subTagsForm.values.subTags?.map(subTag => (
                <>
                  <Badge variant="default" icon="categories">
                    {(subTags as Array<DecoratedActivitySubTag>).find(({ id: subTagId }) => subTag === subTagId)?.name}
                  </Badge>
                  <Spacer size={2} />
                </>
              ))}
              {inputsEditable && (
                <>
                  {!subTagsForm.values.insufficientInformation &&
                    !subTagsForm.values.notEducation &&
                    !subTagsForm.values.subTags.length && (
                      <FlagBadge variant="warning">{t('typography.eligibilityReviewRequired')}</FlagBadge>
                    )}

                  <ListItemButton
                    title={t(`button.addTags`)}
                    onClick={() => setIsSubTagsModalOpened(true)}
                    icon={<PencilSVG />}
                  />
                </>
              )}
            </NonProfitDetailListItem>
          )}

          <NonProfitDetailListItem title={t('table.address')}>
            <span>
              {validation?.addressLine1}
              {validation?.addressLine1 && <br />}
              {validation?.addressLine2}
              {validation?.addressLine2 && <br />}
              {validation?.city}
              {validation?.city && <br />}
              {validation?.postal}
              {validation?.postal && <br />}
              {validation?.countryCode && getRegion(validation!.countryCode)}
            </span>
          </NonProfitDetailListItem>
          <NonProfitDetailListItem title={t('table.website')}>
            <a href={getProperHref(String(validation?.website))} target="_blank" rel="noopener noreferrer">
              {validation?.website}
            </a>
            {inputsEditable && (
              <ListItemButton
                title={t(`button.editWebsite`)}
                onClick={() => setWebsiteModalOpened(true)}
                icon={<PencilSVG />}
              />
            )}
          </NonProfitDetailListItem>
          <NonProfitDetailListItem title={t('table.email')}>
            <a href={`mailto:${validation?.email}`}>{validation?.email}</a>
          </NonProfitDetailListItem>

          <NonProfitDetailListItem title={t('table.registryName')}>
            <span>
              {selectedRegistryData?.registryInformationNotFound
                ? t('typography.notFound')
                : selectedRegistryData?.registry?.name && capitalize(selectedRegistryData?.registry?.name)}
            </span>
            {inputsEditable &&
              !selectedRegistryData?.registry.id &&
              selectedRegistryData?.registryInformationNotFound !== true && (
                <FlagBadge variant="warning">{t('typography.registryReviewRequired')}</FlagBadge>
              )}
            {inputsEditable && (
              <ListItemButton
                title={t(`button.editRegistry`)}
                onClick={() => setRegistryModalOpened(true)}
                icon={<PencilSVG />}
              />
            )}
          </NonProfitDetailListItem>
          {registryData && (
            <NonProfitDetailListItem title={t('table.registryEnglishName')}>
              <span>
                {validation && selectedRegistryData?.registryInformationNotFound
                  ? t('typography.notFound')
                  : selectedRegistryData?.registry?.englishName &&
                    capitalize(selectedRegistryData?.registry?.englishName)}
              </span>
            </NonProfitDetailListItem>
          )}
          <NonProfitDetailListItem title={t('table.registryId')}>
            <span>
              {selectedRegistryData?.registryInformationNotFound
                ? t('typography.notFound')
                : selectedRegistryData?.organisationRegistryId}
            </span>
            {((inputsEditable &&
              !selectedRegistryData?.registry.id &&
              selectedRegistryData?.registryInformationNotFound !== true) ||
              registryIdDoesNotMatchRegistryRegex()) && (
              <FlagBadge variant="warning">{t('typography.registryReviewRequired')}</FlagBadge>
            )}
            {isApproved && validationOrganisationHasRelationships(validation) && (
              <FlagBadge variant="default">
                {t(
                  `dialog.relatedOrganisations.badge.${
                    validationOrganisationIsParent(validation) ? RelationshipValues.CHILD : RelationshipValues.PARENT
                  }`
                )}
              </FlagBadge>
            )}
            {!!relatedOrganisations?.length &&
              (relationshipForm.values.type ? (
                <RelationshipBadge relationshipType={relationshipForm.values.type} />
              ) : (
                <FlagBadge variant="warning">{t('typography.registryIdInUse')}</FlagBadge>
              ))}
          </NonProfitDetailListItem>
          {countrySpecificDataFeatureFlag && isIndianNonprofit && (
            <NonProfitDetailListItem title={t('dialog.approveValdiationRequest.fcraId')}>
              <span>{countrySpecificData?.fcra ? countrySpecificData?.fcra : t('typography.notProvided')}</span>
            </NonProfitDetailListItem>
          )}
          <NonProfitDetailListItem title={t('table.partner')}>
            <span>{validation?.partner.name}</span>
          </NonProfitDetailListItem>
          {validation?.configuration?.name && (
            <NonProfitDetailListItem title={t('table.programName')}>
              <span>{validation?.configuration?.name}</span>
            </NonProfitDetailListItem>
          )}
          <NonProfitDetailListItem title={t('table.validationRequestId')}>
            <span>{validation?.id}</span>
          </NonProfitDetailListItem>
          {validation?.organisationId && (
            <NonProfitDetailListItem title={t('table.organisationId')}>
              <Link to={`/organizations/${validation?.organisationId}`}>{validation?.organisationId}</Link>
            </NonProfitDetailListItem>
          )}
          <NonProfitDetailListItem title={t('table.requestDate')}>
            <span>{getDate(validation?.createdAt)}</span>
          </NonProfitDetailListItem>
          <NonProfitDetailListItem title={t('table.country')}>
            <div className={styles.countryDefinition}>
              <a href={t(oracleValidationLink)} target="_blank" rel="noopener noreferrer">
                <span>{validation?.countryCode && getRegion(validation!.countryCode)}</span>
                <ExpandLogo width="28px" height="28px" fill={colors.secondary} />
              </a>
            </div>
          </NonProfitDetailListItem>
        </ul>
      </Grid>
      {!hideSimilarOrganisationsSection && (
        <FormikProvider value={similarOrganisationForm}>
          <SimilarOrganisations
            similarOrganisations={similarOrganisations}
            isLoading={isLoadingSimilarOrganisations}
            isApproved={isApproved}
            registryInformationNotFound={selectedRegistryData?.registryInformationNotFound}
            isIndianNonprofit={isIndianNonprofit}
          />
        </FormikProvider>
      )}
      {!hideRelatedOrganisationsSection && (
        <FormikProvider value={relationshipForm}>
          <RelatedOrganisations
            relatedOrganisations={isApproved ? validation?.organisation?.relationships : relatedOrganisations}
            isLoading={isLoadingRelatedOrganisations}
            hasCorrectRegistryId={!!selectedRegistryData?.registry.id && registryIdDoesNotMatchRegistryRegex()}
            isApproved={isApproved}
            registry={selectedRegistryData?.registry.id}
            registryId={selectedRegistryData?.organisationRegistryId}
          />
        </FormikProvider>
      )}
      <div className={styles.topMargin}>
        <Documents
          isLoading={isLoading || (!!validation?.validationSubmissionId && isLoadingValidationSubmissionDocuments)}
          documents={documents}
        />
      </div>
      {isApproveModalOpened && (
        <ApproveModal
          nonprofitName={nonprofitName}
          isOpened={isApproveModalOpened}
          setIsOpened={setIsApproveModalOpened}
          finishAction={refresh}
          validationRequestId={validation!.id}
          validationRegistryId={selectedRegistryData?.organisationRegistryId}
          validationCountryCode={validation!.countryCode}
          registryId={selectedRegistryData?.registry?.id}
          organisationTypes={organisationTypeForm.values.organisationTypes}
          validationOrganisationTypes={validation?.organisationTypes}
          relationshipType={relationshipForm?.values.type}
          relationshipOrganisation={relationshipForm?.values.organisation}
          registryInformationNotFound={selectedRegistryData?.registryInformationNotFound}
          duplicateAction={similarOrganisationForm.values.action}
          duplicateOrganisation={similarOrganisationForm.values.organisation}
          subTags={subTags}
          selectedSubTags={subTagsForm.values.subTags}
          insufficientSubTagsInformation={subTagsForm.values.insufficientInformation}
          noValidSubtags={subTagsForm.values.notEducation}
          countrySpecificData={countrySpecificData}
        />
      )}
      {isRejectModalOpened && (
        <RejectModal
          validationRequestId={validation!.id}
          nonprofitName={nonprofitName}
          partnerName={validation?.partner.name}
          isOpened={isRejectModalOpened}
          setIsOpened={setIsRejectModalOpened}
          finishAction={refresh}
        />
      )}
      <UpdateWebsiteModal
        validation={validation}
        isModalOpened={isWebsiteModalOpened}
        setModalOpened={setWebsiteModalOpened}
        refresh={refresh}
      />
      {isRegistryModalOpened && (
        <RegistryModal
          nonprofitName={nonprofitName}
          isOpened={isRegistryModalOpened}
          setIsOpened={setRegistryModalOpened}
          setSelectedRegistryData={setSelectedRegistryData}
          selectedRegistryData={selectedRegistryData}
          validationCountryCode={validation!.countryCode}
          city={validation?.city}
          website={validation?.website}
          regexRegistryId={registryIdRegex}
          organisationTypes={organisationTypeForm.values.organisationTypes}
          refreshRelatedOrganisations={refreshRelatedOrganisations}
          refreshSimilarOrganisations={refreshSimilarOrganisations}
          countrySpecificData={countrySpecificData}
          setCountrySpecificData={setCountrySpecificData}
          partnerName={validation?.partner.name}
        />
      )}
      {isSubTagsModalOpened && (
        <FormikProvider value={subTagsForm}>
          <SubTagsModal
            isOpened={isSubTagsModalOpened}
            setIsOpened={setIsSubTagsModalOpened}
            subTags={subTags}
            organisationName={name}
          />
        </FormikProvider>
      )}
    </>
  )
}
