import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Dialog, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ReactComponent as Close } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { PartnerName, RejectionReasonsCodeType } from '@percent/admin-dashboard/api/types'
import { RejectModalProps } from './Dialog.types'
import styles from './Dialog.module.scss'
import { Button, Spacer } from '@percent/lemonade'
import { mapRejectionReasonCode } from './mapRejectionReasonCode'
import { FieldDropDown } from '@percent/admin-dashboard/common/components/fieldDropDown/FieldDropDown'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

export function RejectModal({
  isOpened,
  setIsOpened,
  finishAction,
  nonprofitName,
  partnerName,
  validationRequestId
}: RejectModalProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()

  const [{ errorMessage, isLoading, success }, { apiFunc }] = useMutation(
    adminService.rejectValidationRequest,
    finishAction
  )
  const { values, setFieldValue, isValid, dirty } = useFormik({
    initialValues: {
      rejectionReasonCode: ''
    },
    validateOnMount: true,
    validationSchema: () =>
      yup.object().shape({
        rejectionReasonCode: yup.mixed().oneOf(Object.values(RejectionReasonsCodeType))
      }),
    onSubmit: () => {}
  })

  const mapRejectionReasonsCode = (rejectionReasonsCode: RejectionReasonsCodeType[]) =>
    rejectionReasonsCode.map(rejectionReasonCode => ({
      title: t(mapRejectionReasonCode[rejectionReasonCode] as LocaleKey),
      value: rejectionReasonCode
    }))

  const { rejectionReasonCode } = values

  const handleSubmit = () => apiFunc({ payload: { rejectionReasonCode }, id: validationRequestId })

  const isMicrosoftPartner = partnerName?.toLowerCase() === PartnerName.MICROSOFT
  const rejectionReasonsCodes = Object.values(RejectionReasonsCodeType).filter(
    code => isMicrosoftPartner || code !== RejectionReasonsCodeType.SuspiciousApplication
  )

  return (
    <Dialog
      openModal={isOpened}
      onClose={() => setIsOpened(false)}
      headerTitle={t('dialog.rejectValidation')}
      withoutHeader={success}
    >
      {isLoading ? (
        <Loader />
      ) : errorMessage ? (
        <div className={styles.modalBody}>{errorMessage}</div>
      ) : success ? (
        <div className={styles.dialogBody}>
          <div className={styles.close}>
            <Close />
          </div>
          <Typography variant="h5">{t('dialog.hasBeenRejected')}</Typography>
          <Typography className={styles.caption}>
            {nonprofitName} {t('dialog.organisationHasBeenAddedToNonValid')}
          </Typography>
          <PercentButton title={t('button.done')} onClick={() => setIsOpened(false)} className={styles.centerButton} />
        </div>
      ) : (
        <>
          <Grid item xs={12} className={styles.modalBody}>
            <Typography>
              <span style={{ fontFamily: 'MarkProMedium' }}>{nonprofitName} </span>
              {t('dialog.willBeRejected')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.form}>
            <FieldDropDown
              selectTitle={t('form.reasonForRejection')}
              initialValue={rejectionReasonCode}
              placeholder={t('form.selectRejectionReason')}
              onClick={(
                e: React.ChangeEvent<{
                  name?: string | undefined | null
                  value: unknown
                }>
              ) => setFieldValue('rejectionReasonCode', e.target.value)}
              valueArray={mapRejectionReasonsCode(rejectionReasonsCodes)}
            />
          </Grid>
          <Grid item xs={12} className={styles.dialogFooter}>
            <Button
              disabled={!(dirty && isValid)}
              type="submit"
              variant="primary"
              data-testid="btn-reject-validation"
              loading={isLoading}
              onPress={handleSubmit}
            >
              {t('button.reject')}
            </Button>
            <Spacer size={2} />
            <Button
              variant="secondary"
              data-testid="btn-cancel-validiation"
              onPress={() => {
                setIsOpened(false)
              }}
            >
              {t('button.cancel')}
            </Button>
          </Grid>
        </>
      )}
    </Dialog>
  )
}
