import { AxiosInstance } from 'axios'
import countries, { Alpha3Code } from 'i18n-iso-countries'

import { orgSubActivities } from '@percent/admin-dashboard/constants/organisationActivities'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { CurrencyCode } from '@percent/utility'
import { unpayableOrganisationReasons } from '@percent/admin-dashboard/app/organizations/organizationsDetail/components/overviewDetails/paymentDetails/MarkOrganisationAsUnpayableModal'
import { CountrySpecificData } from '@percent/admin-dashboard/app/validations/nonprofitDetail/dialogs/registryModal/RegistryModal.types'

export type Organisation = {
  id: string
  name: string
  email: string | null
  displayName: string | null
  donated: number
  description: string | null
  countryCode: Alpha3Code
  milestones: milestone[]
  categories: categories[]
  type: string
  logo: string | null
  images: string[]
  registry: string
  registryName: string
  registryId: string
  website: string | null
  address: string
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  state: string | null
  postcode: string | null
  country: string | null
  registrationDate: string | null
  registrationActive: boolean
  currencyCode: string
  trustees: string | null
  claimedAt: string | null
  createdAt: string
  registryData?: RegistryData
  relationships?: ValidationRelationship[]
  document?: string
  phone?: string | null
  fieldConstraints?: fieldConstraintsType
}

export type PaymentMethod = {
  bankAccountDetails?: {
    holderName: string
    version: string
    wireTransferDetails: {
      accountNumber: string
      bankCode: string
      type: string
    }
  }
  method: 'bank_transfer' | 'card' | 'cheque' | 'unable_to_pay'
  paymentUrl?: string
  reason?: string
}

type RegistryData = { name: string }

export type categories = {
  id: string
  name: string
  imageUrl: string
  createdAt: string
}

export type milestone = {
  id: number
  amount: number
  reward: string
  currencyCode: string
  organisationId: string
}

export type adminServiceCapabilities = {
  percentClient: AxiosInstance
}

export type Claim = {
  acceptedAt: string | null
  account: {
    email: string
    fullName: string
    id: string
    phoneNumber: string
  }
  accountId: string
  createdAt: string
  deletedAt: string | null
  description: string | null
  id: string
  image: string | null
  organisation: {
    id: string
    name: string
    countryCode: countries.Alpha3Code
  }
  organisationId: string
  rejectedAt: string | null
  validationSubmissionId: string | null
}

export type GetValidationsRequestProps = {
  status?: string | null
  pageSize?: 0
}

export type AcceptValidationRequestProps = {
  registryId: string
}

export type ValidationDocument = {
  id: string
  validationRequestId: string
  url: string
  createdAt: string
  deletedAt: string
}

export type ValidationSubmissionDocumentType = 'validation_request' | 'agent_verification'

export type ValidationSubmissionDocument = {
  id: string
  validationInviteId: string
  url: string
  createdAt: string
  validationSubmissionId: string
  type?: ValidationSubmissionDocumentType
  firstNameFound?: boolean | null
  lastNameFound?: boolean | null
  organisationNameFound?: boolean | null
}

export type fieldConstraintsType = {
  fields: {
    registryId: {
      required: boolean
      regex?: string
    }
  }
}

export type Validation = {
  id: string
  website: string
  email: string
  name: string
  registryName?: string
  registryId?: string
  addressLine1: string
  addressLine2: string
  city: string
  postal: string
  state: string
  countryCode: Alpha3Code
  createdAt: string | null
  deletedAt: string | null
  acceptedAt: string | null
  rejectedAt: string | null
  documents: ValidationDocument[]
  validationSubmissionId: string | null
  partner: {
    id: string
    name: string
  }
  configuration: {
    name: string | null
    allowedOrganisations?: {
      types?: OrganisationTypes[]
    }
  }
  organisation?: Pick<Organisation, 'name' | 'registry' | 'registryId' | 'registryData' | 'relationships'> & {
    countrySpecificData?: CountrySpecificData
  }
  organisationId?: string
  organisationTypes?: OrganisationTypes[]
  organisationSubTags?: { id: string; meetsCriteria: boolean }[] | null
  fieldConstraints?: fieldConstraintsType
}

export type AdminServiceGetPartnerDeveloperListProps = {
  page?: number
  pageSize?: number
  partnerId: string
  filter?: string
}

export type AdminServiceGetPartnerScopeListProps = {
  page?: number
  pageSize?: number
  partnerId: string
}

export type AdminServiceGetRelatedOrganisationsListProps = {
  page?: number
  pageSize?: number
  registry?: string
  registryId?: string
  relationshipType?: string
}

export type AdminServiceGetSimilarOrganisationsListProps = {
  registry?: string
  registryId?: string
  name?: string
  countryCode?: Alpha3Code
  city?: string
  website?: string
  address?: string
  page?: number
  pageSize?: number
}

export type AdminServiceGetValidationSubmissionDocumentsProps = {
  page?: number
  pageSize?: number
  validationSubmissionId?: string | null
}

export type AdminServiceGetPartnerListProps = {
  pageSize?: number
  query?: string
  partnerIds?: string[]
}

export enum VettingStatus {
  CLEAR = 'clear',
  PENDING = 'pending',
  FLAG = 'flag',
  YES = 'yes',
  NO = 'no',
  PASS = 'pass',
  FAIL = 'fail',
  PENDING_REVIEW = 'pending_review'
}

export enum YesNo {
  YES = 'yes',
  NO = 'no'
}

export type ComplianceEventType =
  | 'commercial'
  | 'controversial'
  | 'hateSpeech'
  | 'compliance'
  | 'adverseMedia'
  | 'sanction'
  | 'warning'

export type VettingEvent = {
  id: string
  organisationId: string
  scope: ComplianceEventType
  result?: {
    status: YesNo
  }
  status: VettingStatus
  createdBy: string | null
  expiresAt: string | null
  createdAt: string
  deletedAt: string | null
  account: {
    id: string
    fullName: string | null
    email: string
  } | null
}

export type AutomatedVettingEventResults = {
  results?: Record<
    string,
    {
      status: VettingStatus
    }
  >
  createdAt?: string
  complyAdvantageSearchId?: string
}

export type QueryParams = {
  status?: string
  completed?: string
  accepted?: string
  rejected?: string
}

export type CursorPaginationData<T> = {
  data: [T]
  object: string
  totalResults: number
  pageNumber: number
  pageSize: number
  _links: {
    current: string
    next: string
    prev: string
  }
}

export type PatchOrganisationParams = {
  payload: {
    displayName?: string | null
    description?: string | null
    registrationActive?: boolean
    website?: string | null
    email?: string | null
    registry?: string
    registryId?: string
    name?: string
    phone?: string | null
    addressLine1?: string | null
    addressLine2?: string | null
    city?: string | null
    state?: string | null
    postcode?: string | null
    country?: string | null
    logo?: string | null | FormData
    featureFlagDelphi92?: boolean
    postal?: string | null
  }
  id: string
}

export type UnableToPayReasons = typeof unpayableOrganisationReasons[number]

export type UpdateOrganisationPaymentMethodParams = {
  payload:
    | { method: 'card'; paymentUrl: string }
    | { method: 'cheque' }
    | { method: 'unable_to_pay'; reason: UnableToPayReasons }
  id: string
}

export type FetchOrganisationsParams = {
  query?: string | null
  countryCode?: string | null
  page?: number
  pageSize?: number
}

export type claims = {
  id: string
  accountId: string
  organisationId: string
  image: string
  description: string
  createdAt: string
  deletedAt: string
  acceptedAt: string
  rejectedAt: string
  organisation: {
    id: string
    name: string
    countryCode: Alpha3Code
  }
  account: {
    id: string
    fullName: string
    email: string
    phoneNumber: string
  }
}

export type FetchClaimsParams = {
  accepted?: boolean
  rejected?: boolean
  pageSize?: number
}

export type FetchRegistriesParams = {
  countryCode: string
}

export type FetchVettingEventsParams = {
  organisationId?: string
  pageSize?: number
}

export type Partner = {
  createdAt: string
  currencyCode: string
  deletedAt: string
  id: string
  image: string | null
  name: string
  publicKey: string
  allowedActivitySubTags: string[] | null
}

export type Subsidiary = {
  id: string
  name: string
  currencyCode: CurrencyCode
  location?: string
}

export enum AgentVerificationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING_REVIEW = 'pending_review',
  PENDING_USER_VERIFICATION = 'pending_user_verification',
  PERCENT_ACTIONABLE = 'percent_actionable',
  EXTERNAL_ACTIONABLE = 'external_actionable'
}

export enum AgentVerificationState {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PERCENT_ACTIONABLE = 'percent_actionable',
  EXTERNAL_ACTIONABLE = 'external_actionable'
}

export enum ActivityCheckStatus {
  PENDING_VALIDATION_REQUEST = 'pending_validation_request',
  VALIDATION_REQUEST_FAILED = 'validation_request_failed',
  PENDING = 'pending',
  LIVE = 'live',
  CANCELLED = 'cancelled'
}

export type ValidationRequest = {
  acceptedAt: string | null
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  countryCode: string
  createdAt: string
  deletedAt: string | null
  email: string | null
  id: string
  name: string
  organisationId: string | null
  partnerId: string
  postal: string | null
  registryId: string
  registryName: string
  rejectedAt: string | null
  rejectionReason: string | null
  state: string | null
  website: string | null
  usaGroupExempt: string | null
}

export type ActivityCheck = {
  activity: keyof typeof orgSubActivities
  createdAt: string | null
  countryCode: Alpha3Code
  id: string
  missionStatement: string | null
  organisationActivity: {
    id: string
    createdAt: string
    createdBy: string
    activity: keyof typeof orgSubActivities
  } | null
  organisationId: string | null
  partnerId: string | null
  partnerName: string | null
  status: ActivityCheckStatus
  validationRequest?: ValidationRequest
  organisation: {
    id: string | null
    name: string | null
  }
  validationRequestId: string
}

export type FetchMonitoringParams = {
  status?: string
  pageSize?: number
}

export type MonitoringList = {
  id: string
  countryCode: Alpha3Code
  organisationId: string
  organisationName: string
  results:
    | {
        warning: string
        sanction: string
        hateSpeech: string
        compliance: string
        commercial: string
        adverseMedia: string
        controversial: string
      }
    | {}
  createdAt: string
  partnerName: string | null
  partnerId: string
}

export type PatchValidationRequestParams = {
  payload: {
    website?: string | null
  }
  id: string
}

export enum RejectionReasonsCodeType {
  NotEligible = 'not_eligible',
  IncorrectDocumentation = 'incorrect_documentation',
  NotAttributableToRegistry = 'not_attributable_to_registry',
  SuspiciousApplication = 'suspicious_application',
  Other = 'other'
}

export type PostRejectValidationRequest = {
  rejectionReasonCode: RejectionReasonsCodeType | string
}

export type PartnerScope = {
  createdAt: string
  deletedAt: string | null
  id: string
  partnerId: string
  scopeName: string
}

export type OrganisationRelationship = {
  relationship: 'parent' | 'child'
  result: {
    id: string
    name: string
    organisationTypes: OrganisationTypes[]
    address: Address | string
  }
}

export type SimilarOrganisation = {
  id: string
  name: string
  organisationTypes: OrganisationTypes[]
  website?: string
  countryCode: Alpha3Code
  registry: string
  registryId: string
  address: {
    addressLine1?: string
    addressLine2?: string
    city?: string
    postal?: string
    state?: string
  }
}

export type ValidationRelationship = OrganisationRelationship & { type: 'merge' | 'branch' }

export type Address = { addressLine1?: string; addressLine2?: string; city?: string; postal?: string; state?: string }

export type PostOrganisationLogoProps = {
  payload: {
    formData: object
  }
  organisationId?: string
}

export type OrganisationLogoBody = {
  url: string
}

export type CreateOrganizationProps = {
  payload: {
    formData: object
    file?: File
  }
}

export type GetSignedUrlForDocumentProps = {
  documentUrl: string
}

export type GetSignedUrlForDocumentBody = {
  signedUrl: string
  documentId: string
}

export type VettingEventScope = 'sanction' | 'warning' | 'adverseMedia' | 'hateSpeech' | 'controversial' | 'commercial'

export type SubmitVettingEventsPayload = {
  flags: {
    scope: VettingEventScope
    status: string
  }[]
}

export type OrganisationAddressRow = {
  organisationId: string
  address: string
  postalCode?: string
  city: string
  state: string
}

export type OrganisationsBatchUpdateProps = {
  payload: OrganisationAddressRow[]
}

export enum PartnerName {
  GOOGLE = 'google',
  CANVA = 'canva',
  MICROSOFT = 'microsoft'
}
