import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockAgentVerificationsResponse } from '@percent/admin-dashboard/fixtures/api/mockAgentVerificationsResponse'
import { mockAgentVerificationLogsResponse } from '@percent/admin-dashboard/fixtures/api/mockAgentVerificationLogsResponse'
import { mockDomainVerificationResponse } from '@percent/admin-dashboard/fixtures/api/mockDomainVerificationResponse'

export const agentVerfifcationsHandlers = [
  rest.get(`${config.urls.api}v1/admin/agent-verifications`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAgentVerificationsResponse))
  }),
  rest.get(`${config.urls.api}v1/admin/agent-verifications/:id/logs`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAgentVerificationLogsResponse))
  }),
  rest.post(`${config.urls.api}v1/admin/agent-verifications/:id/request-document`, async (_, res, ctx) => {
    return res(ctx.status(204), ctx.json([]))
  }),
  rest.get(`${config.urls.api}v1/admin/agent-verifications/:id/domain-verification`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockDomainVerificationResponse))
  })
]
