import { useTranslation } from 'react-i18next'

import { Icon } from '@percent/lemonade'
import { Action } from './Action'
import { BankDetailsDocumentActionProps } from './BankDetailsDocumentAction.types'

export function BankDetailsDocumentAction({ log }: BankDetailsDocumentActionProps) {
  const { t } = useTranslation()

  return (
    <Action>
      <Icon name="code-file" size={6} color="gray800" />
      <span>{t('bankDetails.review.documentSubmitted', { documentFileName: log.document.fileName })}</span>
    </Action>
  )
}
