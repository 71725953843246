import styled from 'styled-components'

export const Action = styled.span`
  ${() =>
    `
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    `}
`
