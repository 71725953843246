import React from 'react'
import { useTranslation } from 'react-i18next'

import { BankDetailStatus } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import ApprovedStatusIcon from '@percent/admin-dashboard/common/assets/images/approved_status.svg'
import PendingStatusIcon from '@percent/admin-dashboard/common/assets/images/pending_status.svg'
import RejectedStatusIcon from '@percent/admin-dashboard/common/assets/images/rejected_status.svg'
import styles from '@percent/admin-dashboard/app/bankDetails/bankDetailsContainer/bankDetailsTable/BankDetailsTable.module.scss'

const icon = {
  approved: ApprovedStatusIcon,
  pending: PendingStatusIcon,
  rejected: RejectedStatusIcon,
  deleted: RejectedStatusIcon
}

export function StatusIcon({ status, className }: { status: BankDetailStatus; className?: string }) {
  return <img src={icon[status]} className={className} alt="status-icon" />
}

export function StatusBadge({ status }: { status: BankDetailStatus }) {
  const { t } = useTranslation()

  return (
    <span className={styles[status]} data-testId="status-badge">
      <StatusIcon status={status} />
      {t(`status.${status}`)}
    </span>
  )
}
