import { CardContent, Badge, Drawer, Grid, Text } from '@percent/lemonade'
import { VerificationConfigurationSidebarProps } from './VerificationConfigurationSidebar.types'
import { useTranslation } from 'react-i18next'
import { mapOrganisationTypes } from '../../utils'
import { useMemo } from 'react'
import styles from './VerificationConfigurationSidebar.module.scss'

export function VerificationConfigurationSidebar({
  configuration,
  activityTags
}: VerificationConfigurationSidebarProps) {
  const { t } = useTranslation()
  const activitySubTags = useMemo(() => activityTags?.flatMap(activityTag => activityTag.subTags), [activityTags])
  const truthyExceptionRules = configuration?.exceptionRules?.filter(rule => Boolean(rule))

  return (
    <Drawer title={t('drawer.configuration.title')} buttonText={t('drawer.configuration.buttonText')} position="right">
      <CardContent>
        {t('drawer.configuration.description', {
          name: configuration.name || t('typography.unnamedProgram')
        })}
      </CardContent>
      {configuration.allowedOrganisationsTypes.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={8}>
                  <Text fontWeight="medium">{t('typography.organisationType')}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text fontWeight="medium">{t('typography.outcome')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              <Grid className={styles.gridInner}>
                <Grid.Col span={8}>
                  <div className={styles.flexContainer}>
                    {configuration.allowedOrganisationsTypes.map(type => (
                      <Badge
                        variant={mapOrganisationTypes(type)?.status}
                        icon={mapOrganisationTypes(type)?.icon}
                        key={`org-types-${type}`}
                      >
                        {t(mapOrganisationTypes(type)?.text as any)}
                      </Badge>
                    ))}
                  </div>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Badge variant="positive" icon="check-badge">
                    {t('typography.approval')}
                  </Badge>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </CardContent>
      )}
      {configuration?.allowedActivitySubTags && configuration?.allowedActivitySubTags?.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={8}>
                  <Text fontWeight="medium">{t('typography.activityTags')}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text fontWeight="medium">{t('typography.outcome')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              <Grid className={styles.gridInner}>
                <Grid.Col span={8}>
                  <div className={styles.flexContainer}>
                    {configuration?.allowedActivitySubTags?.map(tag => (
                      <Badge key={`allowed-activity-sub-tag-${tag}`} variant="default">
                        {activitySubTags?.find(activitySubTag => activitySubTag.id === tag)?.name}
                      </Badge>
                    ))}
                  </div>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Badge variant="positive" icon="check-badge">
                    {t('typography.approval')}
                  </Badge>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </CardContent>
      )}

      {truthyExceptionRules && truthyExceptionRules?.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={12}>
                  <Text fontWeight="medium">{t('typography.exceptionRules')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              {truthyExceptionRules.map((exception, index) => (
                <Grid className={styles.gridInner}>
                  <Grid.Col
                    span={12}
                    className={index < truthyExceptionRules.length - 1 ? styles.exceptionGridColumn : undefined}
                  >
                    <div>{exception}</div>
                  </Grid.Col>
                </Grid>
              ))}
            </Grid.Col>
          </Grid>
        </CardContent>
      )}
      {configuration?.complianceFlags && configuration?.complianceFlags?.length > 0 && (
        <CardContent>
          <Grid className={styles.gridContainer}>
            <Grid.Col span={12} className={styles.gridColumn}>
              <Grid style={{ padding: '8px 8px' }}>
                <Grid.Col span={8}>
                  <Text fontWeight="medium">{t('typography.complianceFlags')}</Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Text fontWeight="medium">{t('typography.outcome')}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} className={styles.lastGridColumn}>
              <Grid className={styles.gridInner}>
                <Grid.Col span={8}>
                  <div className={styles.flexContainer}>
                    {configuration?.complianceFlags?.map(flag => (
                      <Badge key={`compliance-flag-${flag}`} variant="default">
                        {t(`typography.${flag}`)}
                      </Badge>
                    ))}
                  </div>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Badge variant="critical" icon="reject">
                    {t('typography.rejection')}
                  </Badge>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </CardContent>
      )}
    </Drawer>
  )
}
