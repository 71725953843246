import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import styles from './BankDetailsAddNoteForm.module.scss'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import { RichTextEditor } from '@percent/admin-dashboard/common/components/RichTextEditor/RichTextEditor'
import { logger } from '@percent/admin-dashboard/common/utility/logger'
import { BankDetailsAddNoteFormProps } from './BankDetailsAddNoteForm.props'
import { Spacer } from '@percent/lemonade'
import { BankDetailsAddNoteFormErrorDialog } from './BankDetailsAddNoteFormErrorDialog'

export function BankDetailsAddNoteForm({ reviewId, refreshTimeline }: BankDetailsAddNoteFormProps) {
  const { t } = useTranslation()
  const { bankDetailsService } = useServices()
  const [error, setError] = useState(false)
  const [content, setContent] = useState<string>('')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { apiFunc: callAddBankDetailsNote }] = useMutation(
    bankDetailsService.addBankDetailsNote,
    async () => {
      await setSubmitting(false)
      await resetForm()
      await refreshTimeline()
    },
    () => setError(true)
  )

  const {
    isValid,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    dirty,
    submitForm,
    resetForm,
    setSubmitting,
    getFieldProps
  } = useFormik({
    initialValues: {
      note: ''
    },
    validationSchema: undefined,
    onSubmit: async data => {
      const { note } = data

      try {
        if (!isValid) {
          setError(true)

          return
        }

        await callAddBankDetailsNote({
          reviewId,
          note
        })
      } catch (err) {
        logger(err)
      }
    },
    validateOnBlur: true,
    validateOnChange: true
  })

  useEffect(() => {
    setContent(getFieldProps('note').value)
  }, [getFieldProps, setContent])

  const formValid = isValid && dirty && !isSubmitting

  return (
    <div className={styles.editorContainer}>
      <BankDetailsAddNoteFormErrorDialog error={error} setError={setError} />
      <RichTextEditor
        fieldName="noteContent"
        error={!!errors.note && !!touched.note}
        content={content}
        placeholder={t('editor.bankDetails.notePlaceholder')}
        handleBlur={e => {
          handleBlur(e)
          setFieldTouched('note')
        }}
        handleUpdate={(newValue: string) => setFieldValue('note', newValue)}
      />
      <Spacer size={4} axis="vertical" />
      <div className={styles.buttonWrapper}>
        <PercentButton
          title={t('button.submit')}
          onClick={submitForm}
          className={styles.button}
          testId="bank-details-dialog-add-note-submit-button"
          disabled={!formValid}
        />
      </div>
    </div>
  )
}
