import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { useAgentVerificationDecisionContext } from '../../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'

import {
  AgentVerificationRejectionReasonDropdownCodes,
  FraudulentFlag
} from '@percent/admin-dashboard/api/actions/agentVerification/agentVerification.types'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import styles from './AgentVerificationRejectModal.module.scss'
import { AgentVerificationRejectModalProps } from './AgentVerificationRejectModal.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { FormField, MultiSelect, Modal, Select, ActionModal, AcknowledgeModal } from '@percent/lemonade'
import { getRejectionReasonCodes } from '@percent/admin-dashboard/common/utility/getRejectionReasonCodes'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'
import { PartnerName } from '@percent/admin-dashboard/api/types'

export function AgentVerificationRejectModal({
  agentFullName,
  organisationName,
  refresh,
  allowedOrganisations,
  partnerName
}: AgentVerificationRejectModalProps) {
  const { agentVerificationManualFradulentRejectionFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()
  const { setOpenedModal, isRejectModalOpened } = useAgentVerificationDecisionContext()
  const [isRejected, setIsRejected] = useState(false)
  const [isSuspiciousApplication, setIsSuspiciousApplication] = useState(false)
  const [fraudulentFlags, setFraudulentFlags] = useState<FraudulentFlag[]>([])
  const { agentVerificationService } = useServices()
  const { id } = useParams<{ id: string }>()

  const getFraudulentFlags = useCallback(async () => {
    try {
      const { data: fraudulentFlagsData } = await agentVerificationService.getFraudulentFlags()
      setFraudulentFlags(fraudulentFlagsData.data)
    } catch {
      setFraudulentFlags([])
    }
  }, [agentVerificationService])

  useEffect(() => {
    if (isRejectModalOpened && agentVerificationManualFradulentRejectionFeatureFlag) {
      getFraudulentFlags()
    }
  }, [isRejectModalOpened, agentVerificationManualFradulentRejectionFeatureFlag, getFraudulentFlags])

  const { values, setFieldValue } = useFormik<{
    rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes | ''
    fraudulentRejectionReasons: string[] | []
  }>({
    initialValues: {
      rejectionReasonCode: '',
      fraudulentRejectionReasons: []
    },
    validationSchema: () =>
      yup.object().shape({
        rejectionReasonCode: yup.string().required('Required'),
        fraudulentRejectionReasons: yup.array().when('rejectionReasonCode', {
          is: (rejectionReasoncode: string) =>
            rejectionReasoncode === AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication,
          then: yup.array().of(yup.string().required())
        })
      }),
    onSubmit: () => {}
  })

  const [{ isLoading, errorMessage }, { apiFunc: aproveFunc }] = useMutation(
    agentVerificationService.rejectAgentVerification,
    () => {
      setIsRejected(true)
    }
  )

  const handleReject = () => {
    const rejection = {
      id,
      rejectionReasonCode: values.rejectionReasonCode as AgentVerificationRejectionReasonDropdownCodes
    }

    aproveFunc(
      agentVerificationManualFradulentRejectionFeatureFlag
        ? { ...rejection, fraudulentFlags: values.fraudulentRejectionReasons }
        : rejection
    )
  }

  const handleCloseModalAfterReject = () => {
    refresh()
    setOpenedModal(undefined)
    setIsRejected(false)
  }

  useEffect(() => {
    if (agentVerificationManualFradulentRejectionFeatureFlag) {
      setIsSuspiciousApplication(
        values.rejectionReasonCode === AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication
      )
    }
  }, [values.rejectionReasonCode, agentVerificationManualFradulentRejectionFeatureFlag])

  const isGooglePartner = partnerName?.toLowerCase() === PartnerName.GOOGLE

  const rejectionReasonsOptions = useMemo(
    () =>
      getRejectionReasonCodes(allowedOrganisations, isGooglePartner)
        .filter(
          val =>
            agentVerificationManualFradulentRejectionFeatureFlag ||
            val !== AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication
        )
        .map(reason => ({
          label: t(`dropdown.reject.${reason}`),
          value: reason
        })),
    [agentVerificationManualFradulentRejectionFeatureFlag, t, allowedOrganisations, isGooglePartner]
  )

  const fraudulentReasonsOptions = useMemo(() => {
    const scopes = Array.from(new Set(fraudulentFlags.map(flag => flag.scope)))

    return scopes.map(scope => ({
      title: t(`fraudReason.scope.${scope}`),
      options: fraudulentFlags
        .filter(f => f.scope === scope)
        .map(f => ({ label: t(`fraudReason.label.${f.code}` as LocaleKey), value: f.id }))
    }))
  }, [t, fraudulentFlags])

  return (
    <Modal open={isRejectModalOpened} onClose={() => setOpenedModal(undefined)}>
      {isRejected && (
        <AcknowledgeModal
          handleClose={handleCloseModalAfterReject}
          title={t('typography.reject.information')}
          description={`${agentFullName} ${t('typography.reject.partialInformation')}`}
          result="negative"
          buttonText={t('button.done')}
        />
      )}

      {!isRejected && (
        <ActionModal
          title={t('typography.rejectAgent')}
          primaryButtonText={t('button.reject')}
          primaryBtnTestId="reject-agent"
          secondaryButtonText={t('button.cancel')}
          handleClose={() => setOpenedModal(undefined)}
          handleSubmit={handleReject}
          loading={isLoading}
          disabled={
            !values.rejectionReasonCode || (isSuspiciousApplication && values.fraudulentRejectionReasons.length === 0)
          }
        >
          <div className={styles.formWrapper}>
            <div className={styles.textWrapper}>
              <strong>{agentFullName}&apos;s </strong>
              {t('typography.reject.partOne')} <strong>{organisationName} </strong> {t('typography.reject.partTwo')}
            </div>
            <FormField label={t('dropdown.reject.title')}>
              <Select
                placeholder={t('dropdown.reject.placeholder')}
                defaultValue={
                  values.rejectionReasonCode
                    ? {
                        label: t(`dropdown.reject.${values.rejectionReasonCode}`),
                        value: values.rejectionReasonCode
                      }
                    : undefined
                }
                options={rejectionReasonsOptions}
                onChange={({ value }) => setFieldValue('rejectionReasonCode', value)}
              />
            </FormField>

            {isSuspiciousApplication && (
              <FormField
                label={t('dropdown.reject.fraudReasonTitle')}
                description={t('dropdown.reject.fraudReasonDescription')}
              >
                <MultiSelect
                  placeholder={t('dropdown.reject.fraudReasonPlaceholder')}
                  options={fraudulentReasonsOptions}
                  onChange={fraudulentReasons =>
                    setFieldValue(
                      'fraudulentRejectionReasons',
                      fraudulentReasons.map(fraudulentReason => fraudulentReason.value)
                    )
                  }
                />
              </FormField>
            )}
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
          </div>
        </ActionModal>
      )}
    </Modal>
  )
}
