import { useTranslation } from 'react-i18next'
import { useCallback, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useParams } from 'react-router-dom'

import { useAgentVerificationDecisionContext } from '../../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'

import { Modal, ActionModal, Alert, AcknowledgeModal, FormField, Select } from '@percent/lemonade'
import {
  agentVerificationApprovalCodes,
  AgentVerificationUpdateStatusModalProps
} from './AgentVerificationUpdateStatusModal.types'
import {
  AgentVerificationApprovalReasonDropdownCodes,
  AgentVerificationRejectionReasonDropdownCodes,
  AgentVerificationStatus,
  PartnerName
} from '@percent/admin-dashboard/api/types'
import { getRejectionReasonCodes } from '@percent/admin-dashboard/common/utility/getRejectionReasonCodes'
import styles from './AgentVerificationUpdateStatusModal.module.scss'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

export function AgentVerificationUpdateStatusModal({
  agentFullName: agentName,
  organisationName,
  agentVerificationStatus,
  refresh,
  allowedOrganisations,
  partnerName
}: AgentVerificationUpdateStatusModalProps) {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { setOpenedModal, isUpdateStatusModalOpened } = useAgentVerificationDecisionContext()
  const { agentVerificationService } = useServices()

  const [isStatusUpdated, setIsStatusUpdated] = useState(false)

  const isInApprovedState = agentVerificationStatus === AgentVerificationStatus.APPROVED
  const isGooglePartner = partnerName?.toLowerCase() === PartnerName.GOOGLE

  const onSuccess = () => {
    setIsStatusUpdated(true)
  }

  const [
    { isLoading: isRejectAgentVerificationLoading, errorMessage: rejectAgentVerificationErrorMessage },
    { apiFunc: rejectAgentVerification }
  ] = useMutation(agentVerificationService.rejectAgentVerification, onSuccess)

  const [
    { isLoading: isApproveAgentVerificationLoading, errorMessage: approveAgentVerificationErrorMessage },
    { apiFunc: approveAgentVerification }
  ] = useMutation(agentVerificationService.approveAgentVerification, onSuccess)

  const { values, setFieldValue, submitForm } = useFormik<{
    updateReasonCode:
      | AgentVerificationRejectionReasonDropdownCodes
      | AgentVerificationApprovalReasonDropdownCodes
      | undefined
  }>({
    initialValues: {
      updateReasonCode: undefined
    },
    validationSchema: () =>
      yup.object().shape({
        updateReasonCode: yup.string().required('Required')
      }),
    onSubmit: () => {
      if (isInApprovedState) {
        rejectAgentVerification({
          id,
          rejectionReasonCode: values.updateReasonCode as AgentVerificationRejectionReasonDropdownCodes
        })
      } else {
        approveAgentVerification({
          id,
          approvalReasonCode: values.updateReasonCode as AgentVerificationApprovalReasonDropdownCodes
        })
      }
    }
  })

  const closeModal = () => setOpenedModal(undefined)

  const handleCloseModalAfterStatusUpdate = () => {
    refresh()
    closeModal()
    setIsStatusUpdated(false)
  }

  const getOptionLabel = useCallback(
    (reason: AgentVerificationRejectionReasonDropdownCodes | AgentVerificationApprovalReasonDropdownCodes) => {
      return t(
        isInApprovedState ? (`dropdown.reject.${reason}` as LocaleKey) : (`dropdown.approve.${reason}` as LocaleKey)
      )
    },
    [t, isInApprovedState]
  )

  const updateReasonsOptions = useMemo(
    () =>
      (isInApprovedState
        ? getRejectionReasonCodes(allowedOrganisations, isGooglePartner).filter(
            val => val !== AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication
          )
        : agentVerificationApprovalCodes
      ).map(reason => ({
        label: getOptionLabel(reason),
        value: reason
      })),
    [isInApprovedState, allowedOrganisations, getOptionLabel, isGooglePartner]
  )
  const errorMessage = rejectAgentVerificationErrorMessage || approveAgentVerificationErrorMessage

  return (
    <Modal open={isUpdateStatusModalOpened} onClose={closeModal}>
      {isStatusUpdated && (
        <AcknowledgeModal
          title={t(
            isInApprovedState
              ? `dialog.updateAvStatus.requestIsRejected.title`
              : 'dialog.updateAvStatus.requestIsApproved.title'
          )}
          description={t(
            isInApprovedState
              ? `dialog.updateAvStatus.requestIsRejected.description`
              : 'dialog.updateAvStatus.requestIsApproved.description',
            { agentName, organisationName }
          )}
          handleClose={handleCloseModalAfterStatusUpdate}
          result={isInApprovedState ? 'negative' : 'positive'}
          buttonText={t('button.done')}
        />
      )}

      {!isStatusUpdated && (
        <ActionModal
          title={t('dialog.updateAvStatus.title')}
          primaryButtonText={t('typography.update')}
          primaryBtnTestId="update-av-status"
          secondaryButtonText={t('button.cancel')}
          handleClose={closeModal}
          handleSubmit={submitForm}
          loading={isRejectAgentVerificationLoading || isApproveAgentVerificationLoading}
          disabled={!values.updateReasonCode}
        >
          <div className={styles.formContainer}>
            <Alert variant="warning">{t('dialog.updateAvStatus.note')}</Alert>
            <span>
              {t(
                isInApprovedState
                  ? 'dialog.updateAvStatus.requestWillBeRejected'
                  : 'dialog.updateAvStatus.requestWillBeApproved',
                { agentName, organisationName }
              )}
            </span>
            <FormField label={t('dialog.updateAvStatus.form.reason.label')} necessity="required">
              <Select
                placeholder={t('dialog.updateAvStatus.form.reason.placeholder')}
                onChange={({ value }) => setFieldValue('updateReasonCode', value)}
                options={updateReasonsOptions}
                defaultValue={
                  values.updateReasonCode && {
                    label: getOptionLabel(values.updateReasonCode),
                    value: values.updateReasonCode
                  }
                }
              />
            </FormField>
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
          </div>
        </ActionModal>
      )}
    </Modal>
  )
}
