import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Tabs,
  Tab,
  Toolbar,
  Grid
} from '@material-ui/core'

import { PaginationActions, Loader, TableHeadSorter, NoResultTable } from '@percent/admin-dashboard/common/components'
import { TableProps } from './Table.types'
import styles from './Table.module.scss'
import { Tabs as LemonadeTabs } from '@percent/lemonade'

export function Table({
  isLoading,
  totalResults,
  data,
  previousPage,
  nextPage,
  value,
  columns,
  handleChange,
  handleRequestSort,
  order,
  orderBy,
  tabs,
  title,
  children,
  emptyTableText,
  emptyTableChildren,
  className,
  emptyTableImageType,
  filtersContent,
  noFooter,
  noWrapper,
  testId = 'table',
  active,
  onChangeTab,
  items,
  emptyTableSize = 'big'
}: TableProps) {
  const tableContent = () => (
    <>
      {title && <Toolbar className={styles.toolbar}>{title}</Toolbar>}
      {/* TODO: remove <Tabs /> once the lemonade tabs migration is complete */}
      {tabs && (
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          className={styles.tabs}
        >
          {tabs.map(tab => (
            <Tab key={tab} label={tab} disableRipple />
          ))}
        </Tabs>
      )}
      {items && active && onChangeTab && <LemonadeTabs activeTab={active} onChangeTab={onChangeTab} items={items} />}
      {filtersContent && <div className={styles.filtersContent}>{filtersContent}</div>}
      <MuiTable className={styles.table} data-testid={testId}>
        {isLoading ? (
          <TableBody>
            <TableRow>
              <TableCell align="center">
                <Loader />
              </TableCell>
            </TableRow>
          </TableBody>
        ) : totalResults === 0 ? (
          <TableRow>
            <TableCell align="center">
              <NoResultTable
                text={emptyTableText || ''}
                type={emptyTableImageType ?? 'default'}
                actionsRow={emptyTableChildren}
                size={emptyTableSize}
              />
            </TableCell>
          </TableRow>
        ) : (
          <>
            {columns && (
              <TableHeadSorter order={order} orderBy={orderBy} onRequestSort={handleRequestSort} cells={columns} />
            )}
            <TableBody>{children}</TableBody>
            {!noFooter ? (
              <TableFooter>
                <TableRow>
                  <TableCell className={styles.footerCell} colSpan={10}>
                    <PaginationActions
                      pageCount={data?.length ? data.length : 0}
                      count={totalResults}
                      nextPage={nextPage}
                      previousPage={previousPage}
                      firstPage={null}
                      lastPage={null}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            ) : null}
          </>
        )}
      </MuiTable>
    </>
  )

  if (noWrapper) {
    return tableContent()
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableContainer component={Paper} className={`${className} ${styles.tableContainer}`}>
          {tableContent()}
        </TableContainer>
      </Grid>
    </Grid>
  )
}
