import {
  Dropzone as MantineDropzone,
  MIME_TYPES,
  FileWithPath,
} from '@mantine/dropzone'
import styled from 'styled-components'
import { ReactComponent as ThumbnailIcon } from '../../assets/images/icons/thumbnail.svg'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg'
import { DropzoneProps } from './dropzone.types'

export const DropzoneContainer = styled(MantineDropzone)<{
  disabled: boolean
}>`
  ${({ theme, disabled }) =>
    `
    outline-color: ${theme.colors.info400};
    outline-offset: 3px;
    width: 100%;
    
    > div {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      padding: ${theme.sizes[10]};
      border-radius: ${theme.sizes[1]};
      border: 1px dashed #e9e8eb;
      align-items: center;
      gap: ${theme.sizes[4]};

      :hover {
        background: ${theme.colors.primarySurface};
        cursor: pointer;
      }
    }

    &[data-accept] > div {
      background: ${theme.colors.info30};
    }

    &[data-accept] > div > div > svg > path,
    &[data-accept] h6 {
      color: ${theme.colors.info400};
      fill: ${theme.colors.info400};
    }
  
    &[data-reject] > div {
      background: #fce2e3;
      color: #e71a22;
    }
  
    &[data-reject],
    &[data-reject] > div > div > svg > path,
    &[data-reject] h6 {
      border: none;
      color: #e71a22;
      fill: #e71a22;
    }

    ${
      disabled &&
      `
        pointer-events: none;
        > div {
          border: none;
          background: ${theme.colors.primarySurface};
        }
    
        > div > div > svg > path, 
        > div > div > span,
        h6 {
          color: #B6B4B9;
          fill: #B6B4B9;
        }
      `
    }
  `}
`

export const DropzoneIconWrapper = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${theme.sizes[14]};
    height: ${theme.sizes[14]};

    > svg {
      > path {
        fill: ${theme.colors.neutral300};
      }
    }
  `}
`

export const DropzoneTextContainer = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    flex-direction: column;
    color: #494550;

    > h6 {
      margin: 0;
      font-size: ${theme.fontSizes.h6};
      font-weight: ${theme.fontWeights.semiBold};
    }

    > span {
      font-size: ${theme.fontSizes.bodySmall};
      color: ${theme.colors.neutral300};
    }
  `}
`

export const FilePreviewContainer = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes[2]};
    margin-top: ${theme.sizes[3]};
    width: 100%;
  `}
`

export const FilePreview = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

  > p {
    margin: 0;
    font-size: ${theme.fontSizes.bodySmall};
    font-weight: ${theme.fontWeights.medium};
    line-height: 24px;
    color: #0d0d0d;

    > span {
      color: ${theme.colors.neutral300};
    }
  }

  > svg {
    margin-right: ${theme.sizes.xxs};
    width: ${theme.sizes[5]};
    height: ${theme.sizes[5]};
    cursor: pointer;
    transition: transform 300ms ease;

    :hover {
      transform: scale(0.8);
    }
  }
  `}
`

const formatBytes = (bytes: number): string => {
  const showInKb = bytes < 1024 * 1024
  const base = showInKb ? 1024 : 1024 * 1024

  return `${(bytes / base).toFixed(2)} ${showInKb ? 'KB' : 'MB'}`
}

export const Dropzone = ({
  title,
  description,
  files,
  onChange,
  maxFiles = 5,
  maxSize = 5 * 1024 * 1024,
  accept = [MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf],
  disabled = false,
  testId,
  filePreviewTestId,
  disableFsAccessApi = false,
}: DropzoneProps) => {
  const removeFile = (file: FileWithPath) =>
    onChange(files.filter((f) => f !== file))

  const onDrop = (newFiles: FileWithPath[]) => {
    const allFiles = [...files, ...newFiles]

    if (allFiles.length <= maxFiles) {
      onChange(allFiles)
    }
  }

  return (
    <>
      <DropzoneContainer
        useFsAccessApi={!disableFsAccessApi}
        onDrop={onDrop}
        maxSize={maxSize}
        maxFiles={maxFiles}
        accept={accept}
        disabled={disabled}
        data-testid={testId}
      >
        <DropzoneIconWrapper>
          <MantineDropzone.Accept>
            <UploadIcon />
          </MantineDropzone.Accept>
          <MantineDropzone.Reject>
            <CloseIcon />
          </MantineDropzone.Reject>
          <MantineDropzone.Idle>
            <ThumbnailIcon />
          </MantineDropzone.Idle>
        </DropzoneIconWrapper>

        <DropzoneTextContainer>
          <h6>{title}</h6>
          <span>{description}</span>
        </DropzoneTextContainer>
      </DropzoneContainer>

      {files.length > 0 && (
        <FilePreviewContainer data-testid={filePreviewTestId}>
          {files.map((file) => (
            <FilePreview key={file.name}>
              <p>
                {file.name} <span>{formatBytes(file.size)}</span>
              </p>
              <CloseIcon
                onClick={() => removeFile(file)}
                data-testid="dropzone-remove-file"
              />
            </FilePreview>
          ))}
        </FilePreviewContainer>
      )}
    </>
  )
}
