import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import styles from '../BankDetailsView.module.scss'

import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'
import { ReactComponent as PencilSVG } from '@percent/admin-dashboard/common/assets/images/pencil.svg'
import { WireTransferType } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { BankDetailsSectionProps } from './BankDetailsAccountSection.types'

export function BankDetailsAccountSection({
  data,
  enableEdit,
  disableApprove,
  bankDetailsSubmitFeatureFlag,
  onEdit
}: BankDetailsSectionProps) {
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.bankDetails')}</Typography>
        {enableEdit && bankDetailsSubmitFeatureFlag && (
          <ListItemButton
            className={styles.editButton}
            title={disableApprove ? t(`button.addDetails`) : t(`button.edit`)}
            onClick={onEdit}
            icon={<PencilSVG />}
            testId="bank-details-edit-button"
          />
        )}
      </Grid>
      <ul className={styles.bankDetailsList}>
        <li>
          <p>{t('typography.accountHolderName')}</p>
          <span data-testId="holder-value">{data?.bankAccountDetails?.holderName || t('typography.infoNotAdded')}</span>
        </li>
        {data?.bankAccountDetails?.wireTransferDetails?.type === WireTransferType.DOMESTIC && (
          <>
            <li>
              <p>{data?.bankAccountDetails?.wireTransferDetails?.bankCodeLabel}</p>
              <span data-testId="bankcode-value">{data?.bankAccountDetails?.wireTransferDetails?.bankCode}</span>
            </li>
            <li>
              <p>{data?.bankAccountDetails?.wireTransferDetails?.accountNumberLabel}</p>
              <span data-testId="account-value">{data?.bankAccountDetails?.wireTransferDetails?.accountNumber}</span>
            </li>
          </>
        )}
        {data?.bankAccountDetails?.wireTransferDetails?.type === WireTransferType.IBAN && (
          <>
            <li>
              <p>{data?.bankAccountDetails?.wireTransferDetails?.bicLabel}</p>
              <span data-testId="bic-value">{data?.bankAccountDetails?.wireTransferDetails?.bic}</span>
            </li>
            <li>
              <p>{data?.bankAccountDetails?.wireTransferDetails?.ibanLabel}</p>
              <span data-testId="iban-value">{data?.bankAccountDetails?.wireTransferDetails?.iban}</span>
            </li>
          </>
        )}
        {(data?.requiredBankAccountDetails?.type || data?.bankAccountDetails?.wireTransferDetails?.type) ===
          WireTransferType.DOMESTIC_WITH_BIC && (
          <>
            <li>
              <p>
                {data?.bankAccountDetails?.wireTransferDetails?.accountNumberLabel || t('typography.accountNumber')}
              </p>
              <span data-testId="account-value">
                {data?.bankAccountDetails?.wireTransferDetails?.accountNumber || t('typography.infoNotAdded')}
              </span>
            </li>
            <li>
              <p>{data?.bankAccountDetails?.wireTransferDetails?.bicLabel || t('typography.bicSwiftCode')}</p>
              <span data-testId="bic-value">
                {data?.bankAccountDetails?.wireTransferDetails?.bic || t('typography.infoNotAdded')}
              </span>
            </li>
            {data?.bankAccountDetails?.wireTransferDetails?.bankCode && (
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.bankCodeLabel}</p>
                <span data-testId="bankcode-value">{data?.bankAccountDetails?.wireTransferDetails?.bankCode}</span>
              </li>
            )}
            {data?.bankAccountDetails?.wireTransferDetails?.branchCode && (
              <li>
                <p>{data?.bankAccountDetails?.wireTransferDetails?.branchCodeLabel}</p>
                <span data-testId="branch-value">{data?.bankAccountDetails?.wireTransferDetails?.branchCode}</span>
              </li>
            )}
          </>
        )}
      </ul>
    </Grid>
  )
}
