import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import {
  mockDomesticBankDetailsListResponse,
  mockIBANBankDetailsListResponse,
  mockDomesticWithBICBankDetailsListResponse,
  mockMultipleDocumentsResponse
} from '@percent/admin-dashboard/fixtures/api/mockBankDetailsResponse'
import { BankDetailsMainView } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import {
  dbDomestic,
  dbIBAN,
  dbDomesticWithBIC,
  dbDocuments
} from '@percent/admin-dashboard/mocks/handlers/bankDetails/db.mock'

mockDomesticBankDetailsListResponse.data.forEach(bankDetails => dbDomestic.bankDetails.create(bankDetails))
mockIBANBankDetailsListResponse.data.forEach(bankDetails => dbIBAN.bankDetails.create(bankDetails))
mockDomesticWithBICBankDetailsListResponse.data.forEach(bankDetails =>
  dbDomesticWithBIC.bankDetails.create(bankDetails)
)
mockMultipleDocumentsResponse.data.documents.forEach(document => dbDocuments.document.create(document))

export const bankDetailsHandlers = [
  rest.get(`${config.urls.api}v1/admin/bank-details-reviews`, async (_, res, ctx) => {
    const bankDetailsList = [...dbIBAN.bankDetails.getAll(), ...dbDomestic.bankDetails.getAll()]

    return res(ctx.status(200), ctx.json({ totalResults: bankDetailsList.length, data: bankDetailsList }))
  }),
  rest.get(`${config.urls.api}v1/admin/organisations/:id/bank-details-review`, async (req, res, ctx) => {
    const { id } = req.params

    const bankDetails =
      dbDomestic.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbIBAN.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbDomesticWithBIC.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      })

    if (bankDetails) {
      return res(ctx.status(200), ctx.json({ data: bankDetails }))
    }

    return res(ctx.status(400))
  }),
  rest.get(`${config.urls.api}v1/admin/organisations/:id/bank-details-review/document`, async (req, res, ctx) => {
    const { id } = req.params

    const bankDetails =
      dbDomestic.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbIBAN.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      })

    if (bankDetails) {
      return res(ctx.status(200), ctx.json({ data: bankDetails.document }))
    }

    return res(ctx.status(400))
  }),
  rest.get(`${config.urls.api}v1/admin/bank-details-reviews/:id`, async (req, res, ctx) => {
    const { id } = req.params

    const bankDetails =
      dbDomestic.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbIBAN.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbDomesticWithBIC.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      })

    if (bankDetails) {
      return res(ctx.status(200), ctx.json({ data: bankDetails }))
    }

    return res(ctx.status(400))
  }),
  rest.get(`${config.urls.api}v1/admin/bank-details-reviews/:id/document`, async (req, res, ctx) => {
    const { id } = req.params

    const bankDetails =
      dbDomestic.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbIBAN.bankDetails.findFirst({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      })

    if (bankDetails) {
      return res(ctx.status(200), ctx.json({ data: bankDetails.document }))
    }

    return res(ctx.status(400))
  }),

  rest.post(`${config.urls.api}v1/admin/organisations/:id/bank-details-review`, async (req, res, ctx) => {
    const { id } = req.params
    const { status } = (await req.json()) as BankDetailsMainView

    const updatedBankDetails =
      dbIBAN.bankDetails.update({
        where: {
          organisationId: {
            equals: id as string
          }
        },
        data: {
          status
        }
      }) ||
      dbDomestic.bankDetails.update({
        where: {
          organisationId: {
            equals: id as string
          }
        },
        data: {
          status
        }
      })

    if (updatedBankDetails) {
      return res(ctx.status(200), ctx.json({ data: updatedBankDetails }))
    }

    return res(ctx.status(400))
  }),

  rest.delete(`${config.urls.api}v1/admin/organisations/:id/bank-details`, async (req, res, ctx) => {
    const { id } = req.params

    const deletedBankDetails =
      dbIBAN.bankDetails.delete({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      }) ||
      dbDomestic.bankDetails.delete({
        where: {
          organisationId: {
            equals: id as string
          }
        }
      })

    if (deletedBankDetails) {
      return res(ctx.status(200), ctx.json({ data: deletedBankDetails }))
    }

    return res(ctx.status(400))
  }),

  rest.get(`${config.urls.api}v1/admin/bank-details-reviews/:id/documents`, async (_, res, ctx) => {
    const documents = dbDocuments.document.getAll()

    return res(
      ctx.status(200),
      ctx.json({
        data: {
          documents
        },
        object: mockMultipleDocumentsResponse.object,
        totalResults: mockMultipleDocumentsResponse.totalResults
      })
    )
  })
]
