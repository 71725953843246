export const API_ERROR_MESSAGE = {
  genericError: 'Sorry, an unknown error occurred, please contact us to see if we can help.',
  twoFaInvalidToken: 'The code you entered is invalid. Please try again.',
  forbiddenPassword: 'We recognise this as a commonly used password. Try something more unique.',
  tooManyChangePasswordRequests: 'We have received too many change password requests for your account.',
  invalidOTPKey: 'You entered an incorrect verification code.',
  domainInUse: 'The requested domain is already in use',
  childOrganisationCannotBeAParent:
    'The Parent Organisation you’ve selected is already a Child - it cannot be a Parent.'
}
