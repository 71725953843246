import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

export type BankDetailsDocument = {
  url: string
}

export type BankDetailsServiceCapabilities = {
  percentClient: AxiosInstance
}

export type BankDetailStatus = 'pending' | 'approved' | 'rejected' | 'deleted'

export enum WireTransferType {
  DOMESTIC = 'DOMESTIC',
  IBAN = 'IBAN',
  DOMESTIC_WITH_BIC = 'DOMESTIC_WITH_BIC'
}

export type BankDetailsBase = {
  id: string
  countryCode: Alpha3Code
  organisationId: string
  organisationName: string
  organisationAddress: string
  status: BankDetailStatus
  requestDate: string
  reference: string
}

type InternationalPaymentDetailsV1 = {
  type: WireTransferType.IBAN
  iban: string
  ibanLabel: string
  bic: string
  bicLabel: string
}

export type BankAccountDetails = {
  version?: string
  holderName?: string
  wireTransferDetails: {
    type?: 'DOMESTIC' | 'IBAN' | 'DOMESTIC_WITH_BIC'
    accountNumber?: string
    accountNumberLabel?: string
    bankCodeLabel?: string
    bankCode?: string
    iban?: string
    ibanLabel?: string
    bicLabel?: string
    bic?: string
    bicCodeLabel?: string
    bicCode?: string
    branchCode?: string
    branchCodeLabel?: string
  }
}

type DomesticPaymentDetailsV1 = {
  type: WireTransferType.DOMESTIC
  accountNumber: string
  accountNumberLabel: string
  bankCode: string
  bankCodeLabel: string
}

type DomesticWithBicPaymentDetailsV1 = {
  type: WireTransferType.DOMESTIC_WITH_BIC
  accountNumber: string
  accountNumberLabel: string
  bic: string
  bicLabel: string
  bankCode?: string
  bankCodeLabel?: string
  branchCode?: string
  branchCodeLabel?: string
}

type PaymentDetails = InternationalPaymentDetailsV1 | DomesticPaymentDetailsV1 | DomesticWithBicPaymentDetailsV1

export type getBankDetailsListProps = {
  statusFilter?: string
  page?: number
  pageSize?: number
}

export type BankDetailsListView = BankDetailsBase & {
  email: string
}

type FieldRules = {
  label: string
  hint: string
  regex: string
  errorMessage: string
  required: boolean
}

type RequiredBankAccountDetails =
  | {
      type: 'DOMESTIC'
      fields: {
        bankCode: FieldRules
        accountNumber: FieldRules
      }
    }
  | {
      type: 'IBAN'
      fields: {
        iban: FieldRules
        bic: FieldRules
      }
    }
  | {
      type: 'DOMESTIC_WITH_BIC'
      fields: {
        accountNumber: FieldRules
        bic: FieldRules
        bankCode?: FieldRules
        branchCode?: FieldRules
      }
    }

export type BankDetailsMainView = BankDetailsBase & {
  claimer: {
    email: string
  }
  previousFilename: string
  bankAccountDetails: {
    holderName: string
    version: string
    wireTransferDetails: PaymentDetails
  }
  requiredBankAccountDetails?: RequiredBankAccountDetails
}

export type GetBankDetailProps = Pick<BankDetailsBase, 'organisationId'>

export type RemoveBankDetailProps = Pick<BankDetailsBase, 'organisationId'>

export type GetBankDetailByIdProps = Pick<BankDetailsBase, 'id'>

export type BankDetailListResponse = {
  data: BankDetailsListView
}

export type BankDetailsDocumentResponse = {
  data: BankDetailsDocument
}

export type PostBankDetailsProps = {
  payload: {
    formData: object
    bankAccountDetails?: BankAccountDetails
  }
}

export type UploadAdditionalBankDetailsDocuments = {
  reviewId: string
  payload: {
    formData: object
  }
}

export type UpdateBankDetailsProps = {
  status: 'rejected' | 'approved' | null
  organisationId: string
}

export type UpdateBankDetailsFormProps = {
  organisationId: string
  payload: {
    holderName: string
    paymentDetails: {
      type: WireTransferType.DOMESTIC_WITH_BIC
      accountNumber: string
      bic: string
      bankCode?: string
      branchCode?: string
    }
  }
}

export type AddBankDetailsNoteProps = {
  reviewId: string
  note: string
}

export type GetBankDetailsReviewRequestNote = {
  reviewId: string
}

type BaseBankDetailsRequestLog = {
  actionAt: string
  author?: {
    id: string
    fullName?: string
    email?: string
  }
}

export type BankDetailsReviewRequestStatusLog = BaseBankDetailsRequestLog & {
  action: 'requested' | 'approved' | 'rejected' | 'deleted'
  affectedEntity: 'review'
}

export type NoteCreatedBankDetailsReviewRequestLog = BaseBankDetailsRequestLog & {
  action: 'created'
  affectedEntity: 'note'
  note: {
    note: string
  }
}

export type DocumentSubmittedBankDetailsReviewRequestLog = BaseBankDetailsRequestLog & {
  action: 'submitted'
  affectedEntity: 'document'
  document: {
    id: string
    fileName: string
    url: string
  }
}

export type BankDetailsReviewRequestLog =
  | BankDetailsReviewRequestStatusLog
  | NoteCreatedBankDetailsReviewRequestLog
  | DocumentSubmittedBankDetailsReviewRequestLog
