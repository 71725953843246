import { useTranslation } from 'react-i18next'

import styles from '../DomainVerification.module.scss'

import { Alert, CardContent, CardDashboard, Spacer } from '@percent/lemonade'
import { OpenAiAnalysisProps } from './OpenAiAnalysis.types'

export function OpenAiAnalysis({ openAiAnalysis }: Readonly<OpenAiAnalysisProps>) {
  const { t } = useTranslation()

  const { languageMatch, fraudIndicators } = openAiAnalysis
  const notAvailable = t('status.na')

  return (
    <CardDashboard title={t('typography.aiAnalysis')}>
      {(!languageMatch.match || fraudIndicators.suspicious) && (
        <>
          <Alert variant="warning" title={t('typography.suspiciousReadReport')} />
          <Spacer axis="vertical" size={4} />
        </>
      )}
      <CardContent title={t('typography.language')}>{languageMatch.analysis || notAvailable}</CardContent>
      <CardContent title={t('typography.fraud')}>
        <span>{fraudIndicators.analysis || notAvailable}</span>
        {fraudIndicators.suspiciousIndicators.length > 0 && (
          <ul className={styles.fraudList} data-testid="openai-indicators">
            {fraudIndicators.suspiciousIndicators.map(indicator => (
              <li key={indicator}>{indicator}</li>
            ))}
          </ul>
        )}
      </CardContent>
    </CardDashboard>
  )
}
