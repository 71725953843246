import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import { throttleAsync } from '@percent/utility'
import {
  GetValidationSubmissionProps,
  ValidationSubmissionsCapabilities,
  ValidationSubmission,
  ValidationSubmissionLog,
  ValidationSubmissionConfiguration,
  ValidationSubmissionConfigurationResponse
} from './validationSubmissions.types'
import { SingleValidationSubmission } from '@percent/domain/verifications'

export const validationSubmissionsService = ({ percentClient }: ValidationSubmissionsCapabilities) => ({
  getValidationSubmissionsList: throttleAsync(
    (params: GetValidationSubmissionProps) =>
      percentClient.get<PBPListResponse<ValidationSubmission>>('/v1/admin/validation-submissions', { params }),
    1000,
    { leading: true, trailing: true }
  ),
  getValidationSubmission: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<SingleValidationSubmission>>(`/v1/admin/validation-submissions/${id}`),
  getValidationSubmissionLogs: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<ValidationSubmissionLog[]>>(`/v1/admin/validation-submissions/${id}/logs`),
  getValidationSubmissionConfigurations: ({ partnerId }: { partnerId: string }) =>
    percentClient.get<PBPGetResponse<ValidationSubmissionConfiguration[]>>(
      `/v1/admin/partners/${partnerId}/validation-submission-configurations`
    ),
  getValidationSubmissionConfiguration: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<ValidationSubmissionConfigurationResponse>>(
      `/v1/admin/validation-submission-configurations/${id}`
    )
})
