import styled from 'styled-components'
import { sanitize } from 'isomorphic-dompurify'

import { Action } from './Action'
import { BankDetailsNoteActionProps } from './BankDetailsNoteAction.types'

export const NoteContent = styled.div`
  ${({ theme }) => `
    & > * {
      box-sizing: border-box;
      margin-top: ${theme.sizes[4]};
      color: ${theme.colors.black}; 
    };
    & > *:not(&:last-child) {
      margin-bottom: ${theme.sizes[4]};
    };
    & > *:last-child {
      margin-bottom: 0 !important;
    };
    h1, h2, h3, h4, h5, h6, b {
      font-weight: ${theme.fontWeights.semiBold};
    };
    h1 {
      margin-top: 0;
      font-size: ${theme.sizes[10]};
      line-height: ${theme.sizes[12]};
    };
    h2 {
      font-size: ${theme.sizes[6]};
      line-height: ${theme.sizes[8]}; 
    };
    h3 {
      font-size: ${theme.sizes[5]};
      line-height: ${theme.sizes[6]};
    };
    p, blockquote, li, dt, dl, a {
      font-size: ${theme.fontSizes.button};
      line-height: ${theme.sizes[6]};
    };
    mark {
      background-color: ${theme.colors.primary100};  
    };
    a {
      color: ${theme.colors.primary};
      transition: color ${theme.transitionTime} ease;

      &:hover {
        color: ${theme.colors.primaryHover};
      }
    };
    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-left: ${theme.sizes[4]};
      border-left: 3px solid ${theme.colors.primary};;
    };
    ul, ol {
      padding-left: ${theme.sizes[10]};
    };
    dl {
      padding-left: 0;
    };
    dt {
      font-style: normal;
      margin-top: ${theme.sizes[3]};
      font-weight: ${theme.fontWeights.medium};
    };
    dd {
      margin-left: ${theme.sizes[4]};
      color: ${theme.colors.gray800};
    };
    li {
      margin-bottom: ${theme.sizes[2]};
    };
    strong {
      font-weight: ${theme.fontWeights.semiBold};
    };
  `}
`

export function BankDetailsNoteAction({ log }: BankDetailsNoteActionProps) {
  return (
    <Action>
      <NoteContent
        dangerouslySetInnerHTML={{
          __html: sanitize(log.note.note) as string
        }}
      />
    </Action>
  )
}
