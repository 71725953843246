import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Paper, Typography } from '@material-ui/core'

import { BankDetailStatus } from '../bankDetailsFormDialog/BankDetailsFormDialog.types'
import { UploadDocumentsModal } from '../uploadDocumentsModal/UploadDocumentsModal'

import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { Button, DocumentsGallery, Spacer } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import styles from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/BankDetailsView.module.scss'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { BankDetailsDocumentErrorDialog } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDocument/BankDetailsDocumentErrorDialog'

type BankDetailsDocumentsProps = {
  refreshTimeline: VoidFunction
  bankDetailsId: string
  bankDetailsStatus: BankDetailStatus
}

export function BankDetailsDocuments({ refreshTimeline, bankDetailsId, bankDetailsStatus }: BankDetailsDocumentsProps) {
  const { t } = useTranslation()
  const { bankDetailsService } = useServices()
  const [openUploadDocumentsModal, setOpenUploadDocumentsModal] = useState(false)
  const { bankDetailsReviewUploadDocumentsFeatureFlag } = useFeatureFlag()

  const [{ data: bankDetailsDocumentData, isLoading, errorMessage }, { refresh }] = useQuery(
    bankDetailsService.getBankDetailsDocumentsByBankDetailsId,
    { id: bankDetailsId }
  )

  const handleUploadDocumentsModal = () => {
    setOpenUploadDocumentsModal(prevState => !prevState)
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
        <Grid item xs={12} className={styles.title}>
          <Typography variant="h6" data-testid="bank-details-documents-gallery">
            {t('typography.documents')}
          </Typography>
          {bankDetailsReviewUploadDocumentsFeatureFlag && bankDetailsStatus === BankDetailStatus.PENDING && (
            <Button
              onPress={handleUploadDocumentsModal}
              type="button"
              variant="primary"
              data-testid="open-upload-documents-modal-button"
              size="small"
            >
              {t('button.uploadDocuments')}
            </Button>
          )}
        </Grid>
        <Spacer axis="vertical" size="s" />
        <Grid item xs={12} className={styles.galleryContainer}>
          <DocumentsGallery
            files={bankDetailsDocumentData?.documents}
            withPreview={bankDetailsDocumentData?.documents?.length > 0}
            previewUrl="/document-preview"
          />
        </Grid>
      </Grid>
      <BankDetailsDocumentErrorDialog error={!!errorMessage} />
      <UploadDocumentsModal
        open={openUploadDocumentsModal}
        onClose={handleUploadDocumentsModal}
        refresh={refresh}
        refreshTimeline={refreshTimeline}
      />
    </>
  )
}
