import { useTranslation } from 'react-i18next'

import { CardDashboard, IllustratedMessage, Loader, Timeline } from '@percent/lemonade'
import { AgentVerificationTimelineProps } from './AgentVerificationTimeline.types'
import { useAgentVerificationTimeline } from './useAgentVerificationTimeline'

export function AgentVerificationTimeline({
  logs,
  isLoadingAgentVerificationLogs
}: Readonly<AgentVerificationTimelineProps>) {
  const { t } = useTranslation()

  const timeline = useAgentVerificationTimeline(logs)

  if (isLoadingAgentVerificationLogs) return <Loader />

  if (logs.length === 0) {
    return (
      <CardDashboard data-testid="agent-verification-empty-timeline">
        <IllustratedMessage illustration="no-results" description={t('typography.noActivity')} />
      </CardDashboard>
    )
  }

  return (
    <CardDashboard title={t('typography.allActivity')} data-testid="agent-verification-timeline">
      <Timeline timeline={timeline} />
    </CardDashboard>
  )
}
