import React from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Alpha3Code } from 'i18n-iso-countries'

import styles from '../BankDetailsView.module.scss'

import { FlagAvatar } from '@percent/lemonade'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { BankDetailsContactSectionProps } from './BankDetailsContactSection.types'

export function BankDetailsContactSection({ data }: BankDetailsContactSectionProps) {
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.contactDetails')}</Typography>
      </Grid>
      <ul className={styles.bankDetailsList}>
        <li>
          <p>{t('typography.country')}</p>
          <span data-testId="country-value">
            <FlagAvatar code3={data?.countryCode as Alpha3Code} />
            {getCountryFromAlpha3(data.countryCode as Alpha3Code)}
          </span>
        </li>
        {data?.organisationAddress && (
          <li>
            <p>{t('typography.address')}</p>
            <span data-testId="address-value">
              {data?.organisationAddress?.split(',').map((address, index, array) => (
                <React.Fragment key={`address-${address.trim()}`}>
                  {address}
                  {index !== array.length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          </li>
        )}
        <li>
          <p>{t('typography.contact')}</p>
          <span data-testId="email-value">{data?.claimer.email}</span>
        </li>
      </ul>
    </Grid>
  )
}
