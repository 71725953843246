import { Box, FormControl, MenuItem, Radio, Select, Typography } from '@material-ui/core'
import classNames from 'classnames/bind'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@percent/lemonade'
import { DisbursementsFiltersProps } from './DisbursementsFilters.types'
import styles from './DisbursementsFilters.module.scss'
import {
  DisbursementStatus,
  DisbursementType
} from '@percent/admin-dashboard/api/actions/disbursements/disbursements.types'
import { useFoundations } from '@percent/admin-dashboard/common/hooks'
import { FoundationNameWithFlag } from '@percent/admin-dashboard/common/components'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

const cx = classNames.bind(styles)

export enum StatusActionType {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  NO_ACTION_REQUIRED = 'NO_ACTION_REQUIRED'
}

export enum TypeActionType {
  CHEQUE = 'CHEQUE',
  CARD = 'CARD',
  BANK_TRANSFER = 'BANK_TRANSFER'
}

const statusFilterOptions: { title: LocaleKey; value: string; description?: LocaleKey }[] = [
  {
    title: 'status.all',
    value: ''
  },
  {
    title: 'dropdown.status.actionRequired.title',
    description: 'dropdown.status.actionRequired.description',
    value: StatusActionType.ACTION_REQUIRED
  },
  {
    title: 'dropdown.status.noActionRequired.title',
    description: 'dropdown.status.noActionRequired.description',
    value: StatusActionType.NO_ACTION_REQUIRED
  }
]

const typeFilterOptions: { title: LocaleKey; value: string; description?: LocaleKey }[] = [
  {
    title: 'typography.all',
    value: ''
  },
  {
    title: 'dropdown.type.cheque.title',
    description: 'dropdown.type.cheque.description',
    value: TypeActionType.CHEQUE
  },
  {
    title: 'dropdown.type.card.title',
    description: 'dropdown.type.card.description',
    value: TypeActionType.CARD
  },
  {
    title: 'dropdown.type.bankTransfer.title',
    description: 'dropdown.type.bankTransfer.description',
    value: TypeActionType.BANK_TRANSFER
  }
]

const dropdownIcon = () => <Icon name="dropdown-arrow-down" size={4} color="primary" />

export function DisbursementsFilters({ queryParams, setQueryParams }: DisbursementsFiltersProps) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()

  const foundationsOptions = useMemo(() => {
    if (foundations) {
      return Object.entries(foundations).map(foundationInfo => ({
        name: foundationInfo[1].name,
        country: foundationInfo[1].countryCode,
        value: foundationInfo[1].id
      }))
    }

    return undefined
  }, [foundations])

  const isStatusOptionSelected = (optionValue: string) => {
    switch (optionValue) {
      case StatusActionType.ACTION_REQUIRED:
        return !!queryParams.anyDisbursementsWithStatus
      case StatusActionType.NO_ACTION_REQUIRED:
        return !!queryParams.allDisbursementsWithStatus
      case '':
        return !queryParams.allDisbursementsWithStatus && !queryParams.anyDisbursementsWithStatus
      default:
        return false
    }
  }

  const isTypeOptionSelected = (optionValue: string) => {
    switch (optionValue) {
      case TypeActionType.CHEQUE:
        return (
          queryParams.anyDisbursementsWithTypes && queryParams.anyDisbursementsWithTypes[0] === DisbursementType.CHEQUE
        )
      case TypeActionType.CARD:
        return (
          queryParams.anyDisbursementsWithTypes &&
          queryParams.anyDisbursementsWithTypes[0] === DisbursementType.EXTERNAL
        )
      case TypeActionType.BANK_TRANSFER:
        return (
          queryParams.anyDisbursementsWithTypes &&
          queryParams.anyDisbursementsWithTypes[0] === DisbursementType.INTERNAL
        )
      case '':
        return !queryParams.anyDisbursementsWithTypes
      default:
        return false
    }
  }

  const getTypeFilterValue = () => {
    if (!queryParams.anyDisbursementsWithTypes) return ''

    if (queryParams.anyDisbursementsWithTypes.includes(DisbursementType.CHEQUE)) return TypeActionType.CHEQUE

    if (queryParams.anyDisbursementsWithTypes.includes(DisbursementType.EXTERNAL)) return TypeActionType.CARD

    if (queryParams.anyDisbursementsWithTypes.includes(DisbursementType.INTERNAL)) return TypeActionType.BANK_TRANSFER

    return ''
  }

  const getTypeFilterRenderValue = (value: TypeActionType) => {
    if (value === TypeActionType.CHEQUE) return t('dropdown.type.cheque.title')

    if (value === TypeActionType.CARD) return t('dropdown.type.card.title')

    if (value === TypeActionType.BANK_TRANSFER) return t('dropdown.type.bankTransfer.title')

    return t('typography.all')
  }

  const getStatusFilterValue = () =>
    queryParams.allDisbursementsWithStatus
      ? StatusActionType.NO_ACTION_REQUIRED
      : queryParams.anyDisbursementsWithStatus
      ? StatusActionType.ACTION_REQUIRED
      : ''

  return (
    <Box className={styles.wrapper}>
      <FormControl>
        <label
          className={styles.selectLabel}
          style={{
            display: queryParams.allDisbursementsWithTypes || queryParams.anyDisbursementsWithTypes ? 'none' : 'block'
          }}
          id="type"
        >
          {t('dropdown.type.title')}
        </label>
        <Select
          id="type"
          variant="filled"
          value={getTypeFilterValue()}
          renderValue={value => `${t('dropdown.type.title')}: ${getTypeFilterRenderValue(value as TypeActionType)}`}
          className={styles.select}
          onChange={event => {
            let newStatuses = {}

            switch (event.target.value) {
              case TypeActionType.CHEQUE:
                newStatuses = {
                  anyDisbursementsWithTypes: [DisbursementType.CHEQUE],
                  allDisbursementsWithTypes: undefined
                }
                break
              case TypeActionType.CARD:
                newStatuses = {
                  anyDisbursementsWithTypes: [DisbursementType.EXTERNAL],
                  allDisbursementsWithTypes: undefined
                }
                break
              case TypeActionType.BANK_TRANSFER:
                newStatuses = {
                  anyDisbursementsWithTypes: [DisbursementType.INTERNAL],
                  allDisbursementsWithTypes: undefined
                }
                break
              default:
                newStatuses = {
                  anyDisbursementsWithTypes: undefined,
                  allDisbursementsWithTypes: undefined
                }
                break
            }

            setQueryParams({
              ...queryParams,
              ...newStatuses
            })
          }}
          IconComponent={dropdownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          inputProps={{
            'data-testid': 'typeSelect'
          }}
        >
          {typeFilterOptions.map((option, i) => (
            <MenuItem
              key={option.title}
              value={option.value}
              className={cx({
                menuItem: true,
                menuItemWithSeparator: i === 0
              })}
            >
              <Box className={styles.statusFilterItemWrapper}>
                <Radio
                  color="primary"
                  checked={isTypeOptionSelected(option.value)}
                  className={styles.menuItemRadio}
                  size="small"
                />
                <Box className={styles.statusFilterItemTextWrapper}>
                  <Typography variant="body1">{t(option.title)}</Typography>
                  {option?.description ? <Typography variant="body1">{t(option.description)}</Typography> : null}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <label
          className={styles.selectLabel}
          style={{
            display: queryParams.allDisbursementsWithStatus || queryParams.anyDisbursementsWithStatus ? 'none' : 'block'
          }}
          id="status"
        >
          {t('dropdown.status.title')}
        </label>
        <Select
          id="status"
          variant="filled"
          value={getStatusFilterValue()}
          renderValue={value =>
            `${t('dropdown.status.title')}: ${t(
              `dropdown.status.${
                value === StatusActionType.ACTION_REQUIRED ? 'actionRequired' : 'noActionRequired'
              }.title`
            )}`
          }
          className={styles.select}
          onChange={event => {
            let newStatuses = {}

            switch (event.target.value) {
              case StatusActionType.ACTION_REQUIRED:
                newStatuses = {
                  anyDisbursementsWithStatus: [
                    DisbursementStatus.PENDING_APPROVAL,
                    DisbursementStatus.PENDING_PAYMENT,
                    DisbursementStatus.SENT
                  ],
                  allDisbursementsWithStatus: undefined
                }
                break
              case StatusActionType.NO_ACTION_REQUIRED:
                newStatuses = {
                  anyDisbursementsWithStatus: undefined,
                  allDisbursementsWithStatus: [DisbursementStatus.PAID, DisbursementStatus.CANCELLED]
                }
                break
              default:
                newStatuses = {
                  anyDisbursementsWithStatus: undefined,
                  allDisbursementsWithStatus: undefined
                }
                break
            }

            setQueryParams({
              ...queryParams,
              ...newStatuses
            })
          }}
          IconComponent={dropdownIcon}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            getContentAnchorEl: null
          }}
          inputProps={{
            'data-testid': 'statusSelect'
          }}
        >
          {statusFilterOptions.map((option, i) => (
            <MenuItem
              key={option.title}
              value={option.value}
              className={cx({
                menuItem: true,
                menuItemWithSeparator: i === 0
              })}
            >
              <Box className={styles.statusFilterItemWrapper}>
                <Radio
                  color="primary"
                  checked={isStatusOptionSelected(option.value)}
                  className={styles.menuItemRadio}
                  size="small"
                />
                <Box className={styles.statusFilterItemTextWrapper}>
                  <Typography variant="body1">{t(option.title)}</Typography>
                  {option?.description ? <Typography variant="body1">{t(option.description)}</Typography> : null}
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {foundationsOptions ? (
        <FormControl>
          <label
            className={styles.selectLabel}
            style={{ display: queryParams.foundationId ? 'none' : 'block' }}
            id="foundation"
          >
            {t('dropdown.foundation')}
          </label>
          <Select
            id="foundation"
            variant="filled"
            value={queryParams.foundationId || ''}
            className={cx(styles.select, styles.foundation)}
            onChange={(event: any) => {
              setQueryParams({ ...queryParams, foundationId: event.target.value || undefined })
            }}
            renderValue={value =>
              `${t('dropdown.foundation')}: ${foundationsOptions.find(option => option.value === value)?.name}`
            }
            IconComponent={dropdownIcon}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            <MenuItem key="all" value="" className={cx(styles.menuItem, styles.menuItemWithSeparator)}>
              <Radio color="primary" checked={!queryParams.foundationId} className={styles.menuItemRadio} />
              {t('status.all')}
            </MenuItem>
            {foundationsOptions.map(({ name, country, value }) => (
              <MenuItem key={value} value={value} className={styles.menuItem}>
                <Radio color="primary" checked={queryParams.foundationId === value} className={styles.menuItemRadio} />
                <FoundationNameWithFlag countryCode={country} name={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}
    </Box>
  )
}
