import { AuthStateContextType } from '@percent/admin-dashboard/context/auth/authContext/AuthContext.types'
import { Action } from './authReducer.types'

export const SET_AUTHORISED = 'auth/set-authorised'
export const SET_UNAUTHORISED = 'auth/set-unauthorised'
export const START_AUTHORISING = 'auth/start-authorising'
export const SET_LOGOUT = 'auth/set-logout'
export const SET_PERMISSIONS = 'auth/set-permissions'

export const initialState = {
  isAuthorised: false,
  isAuthorising: false,
  user: undefined,
  permissionsSet: false
}

export const initializer = (initialValue = initialState) =>
  JSON.parse(localStorage.getItem('authState')!) || initialValue

export const authReducer: (state: AuthStateContextType, action: Action) => AuthStateContextType = (state, action) => {
  switch (action.type) {
    case START_AUTHORISING:
      return {
        ...state,
        isAuthorising: true,
        permissionsSet: false
      }
    case SET_AUTHORISED:
      return {
        ...state,
        user: action.user,
        isAuthorising: false,
        isAuthorised: true
      }
    case SET_PERMISSIONS:
      return {
        ...state,
        permissionsSet: true
      }
    case SET_UNAUTHORISED:
    case SET_LOGOUT:
      return {
        ...state,
        user: undefined,
        isAuthorising: false,
        isAuthorised: false,
        permissionsSet: false
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
