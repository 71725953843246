import React from 'react'
import { ConfirmationModalProps } from './confirmation-modal.types'
import { ModalHeader } from '../modal-header'
import { ModalTitle } from '../modal-title'
import { ModalContent } from '../modal-content'
import { ModalFooter } from '../modal-footer'
import { Button } from '../button'
import { Icon } from '../icon'

export const ConfirmationModal = ({
  title,
  primaryButtonText,
  secondaryButtonText,
  children,
  type,
  variant,
  disabled,
  loading,
  handleClose,
  handleSubmit,
  primaryBtnTestId,
  secondaryBtnTestId,
}: ConfirmationModalProps) => {
  return (
    <>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            flexGrow: 1,
            padding: 4,
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          <Icon name="close" size={4} color="gray600" />
        </div>
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
      <ModalFooter>
        {secondaryButtonText && (
          <Button
            variant="secondary"
            onPress={handleClose}
            data-testid={secondaryBtnTestId}
            size="medium"
          >
            {secondaryButtonText || 'Cancel'}
          </Button>
        )}
        <Button
          onPress={handleSubmit}
          type={type}
          variant={variant}
          disabled={disabled}
          loading={loading}
          data-testid={primaryBtnTestId}
          size="medium"
        >
          {primaryButtonText}
        </Button>
      </ModalFooter>
    </>
  )
}
