import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { BankDetailTimelineTableProps } from './BankDetailsTimelineTable.types'
import { Table } from '@percent/admin-dashboard/common/components/table/Table'
import { getFormattedDate } from '@percent/admin-dashboard/common/library/utility/date'
import { BankDetailsTimelineAction } from './bankDetailsTimelineAction/BankDetailsTimelineAction'
import { BankDetailsReviewRequestLog } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'

export function BankDetailsTimelineTable({ data, isLoading, error, sortOrder }: BankDetailTimelineTableProps) {
  const { t } = useTranslation()
  const [tabValue, setTabValue] = useState(0)

  const [logs, setLogs] = useState(data)

  const tabs = [t('table.all'), t('table.status'), t('table.notes'), t('table.documents')]

  function sortDescending(dataToSort: BankDetailsReviewRequestLog[]) {
    return [...dataToSort.sort((a, b) => new Date(b.actionAt).getTime() - new Date(a.actionAt).getTime())]
  }

  function sortAscending(dataToSort: BankDetailsReviewRequestLog[]) {
    return [...dataToSort.sort((a, b) => new Date(a.actionAt).getTime() - new Date(b.actionAt).getTime())]
  }

  const handleDataUpdate = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number) => {
      setTabValue(newValue)

      let filteredData = data

      if (filteredData === null || data === null) {
        return
      }

      if (newValue === 0) {
        filteredData = data
      }

      if (newValue === 1) {
        filteredData = data.filter((log: BankDetailsReviewRequestLog) => log.affectedEntity === 'review')
      }

      if (newValue === 2) {
        filteredData = data.filter((log: BankDetailsReviewRequestLog) => log.affectedEntity === 'note')
      }

      if (newValue === 3) {
        filteredData = data.filter((log: BankDetailsReviewRequestLog) => log.affectedEntity === 'document')
      }

      setLogs(sortOrder === 'desc' ? sortDescending(filteredData) : sortAscending(filteredData))
    },
    [data, sortOrder]
  )

  useEffect(() => {
    if (!isLoading) {
      handleDataUpdate({} as React.ChangeEvent<{}>, tabValue)
    }
  }, [data, isLoading, handleDataUpdate, tabValue])

  return (
    <Table
      isLoading={(isLoading || !logs) && !error}
      totalResults={logs?.length ?? 0}
      data={logs}
      emptyTableText={t('table.emptyLogs')}
      emptyTableSize="small"
      emptyTableImageType="no-image"
      nextPage={null}
      previousPage={null}
      noFooter
      noWrapper
      tabs={tabs}
      handleChange={handleDataUpdate}
      value={tabValue}
    >
      {logs?.map(row => (
        <TableRow key={v4()}>
          <TableCell style={{ width: '25%' }}>
            {'author' in row ? row.author?.fullName ?? row.author?.email ?? 'Unknown author' : 'Unknown author'} <br />{' '}
            {getFormattedDate(row.actionAt)}
          </TableCell>
          <TableCell style={{ width: '75%' }}>
            <BankDetailsTimelineAction log={row} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
