import {
  AgentVerificationApprovalReasonDropdownCodes,
  AgentVerificationStatus
} from '@percent/admin-dashboard/api/types'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export type AgentVerificationUpdateStatusModalProps = {
  agentFullName: string
  organisationName?: string
  agentVerificationStatus?: AgentVerificationStatus
  refresh: () => void
  allowedOrganisations: OrganisationTypes[]
  partnerName?: string
}

export const agentVerificationApprovalCodes = [
  AgentVerificationApprovalReasonDropdownCodes.AvDocumentAcceptable,
  AgentVerificationApprovalReasonDropdownCodes.GoodstackUpdate
]
