import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Badge, CardContent } from '@percent/lemonade'
import {
  BankDetailsMainView,
  WireTransferType
} from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import styles from './PaymentDetails.module.scss'
import { PaymentMethod } from '@percent/admin-dashboard/api/types'
import { BankDetailStatus } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsFormDialog/BankDetailsFormDialog.types'
import { PaymentTypeWithButton } from './PaymentTypeWithButton'
import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'

const apiBankDetailsReviewStatusToDisplayName = {
  pending: 'Pending',
  approved: 'Approved'
}

export function BankDetailsView({
  paymentMethod,
  paymentMethodRefresh,
  bankDetailsData
}: {
  paymentMethod?: PaymentMethod
  paymentMethodRefresh: VoidFunction
  bankDetailsData: BankDetailsMainView
}) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const handleGoToReviewPage = async () => {
    return push(`/bank-details-reviews/${bankDetailsData?.id}`)
  }

  const wireTransferDetails = bankDetailsData?.bankAccountDetails?.wireTransferDetails

  const bankTransferPaymentMethod = (
    <CardContent
      title={
        paymentMethod?.method === 'card' || paymentMethod?.method === 'cheque'
          ? t('typography.newPaymentMethod')
          : t('typography.paymentMethod')
      }
      testId="bank-details-payment-method"
    >
      {t('typography.bankTransfer')}
    </CardContent>
  )

  return bankDetailsData?.status === BankDetailStatus.APPROVED ||
    bankDetailsData?.status === BankDetailStatus.PENDING ? (
    <>
      {bankTransferPaymentMethod}
      <CardContent title={t('typography.status')} testId="bank-details-status">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Badge
            variant={bankDetailsData?.status === BankDetailStatus.APPROVED ? 'positive' : 'default'}
            icon={bankDetailsData?.status === BankDetailStatus.APPROVED ? 'approve' : 'clock'}
            testId="bank-details-status-badge"
          >
            {apiBankDetailsReviewStatusToDisplayName[bankDetailsData?.status as 'approved' | 'pending']}
          </Badge>
        </div>
      </CardContent>
      <CardContent title={t('typography.accountHolderName')} testId="bank-details-holder">
        {bankDetailsData?.bankAccountDetails?.holderName || t('typography.notAvailable')}
      </CardContent>
      {wireTransferDetails?.type === WireTransferType.DOMESTIC && (
        <>
          <CardContent title={wireTransferDetails?.bankCodeLabel} testId="bank-details-bankcode">
            {wireTransferDetails?.bankCode || t('typography.notAvailable')}
          </CardContent>
          <CardContent title={wireTransferDetails?.accountNumberLabel} testId="bank-details-accountNumber">
            {wireTransferDetails?.accountNumber || t('typography.notAvailable')}
          </CardContent>
        </>
      )}
      {wireTransferDetails?.type === WireTransferType.IBAN && (
        <>
          <CardContent title={wireTransferDetails?.bicLabel} testId="bank-details-bic">
            {wireTransferDetails?.bic || t('typography.notAvailable')}
          </CardContent>
          <CardContent title={wireTransferDetails?.ibanLabel} testId="bank-details-iban">
            {wireTransferDetails?.iban || t('typography.notAvailable')}
          </CardContent>
        </>
      )}
      {wireTransferDetails?.type === WireTransferType.DOMESTIC_WITH_BIC && (
        <>
          {wireTransferDetails?.accountNumberLabel && (
            <CardContent title={wireTransferDetails?.accountNumberLabel} testId="bank-details-accountNumber">
              {wireTransferDetails?.accountNumber || t('typography.notAvailable')}
            </CardContent>
          )}
          {wireTransferDetails?.bicLabel && (
            <CardContent title={wireTransferDetails?.bicLabel} testId="bank-details-bic">
              {wireTransferDetails?.bic || t('typography.notAvailable')}
            </CardContent>
          )}
          {wireTransferDetails?.bankCodeLabel && (
            <CardContent title={wireTransferDetails?.bankCodeLabel} testId="bank-details-bankcode">
              {wireTransferDetails?.bankCode || t('typography.notAvailable')}
            </CardContent>
          )}
          {wireTransferDetails?.branchCodeLabel && (
            <CardContent title={wireTransferDetails?.branchCodeLabel} testId="bank-details-branchcode">
              {wireTransferDetails?.branchCode || t('typography.notAvailable')}
            </CardContent>
          )}
        </>
      )}
      <CardContent title={t('typography.seeBankDetailsReview')}>
        <ListItemButton
          className={styles.reviewButton}
          title={t('button.seeReview')}
          onClick={handleGoToReviewPage}
          testId="go-to-review-page-button"
        />
      </CardContent>
    </>
  ) : paymentMethod?.method === 'bank_transfer' ? (
    <>
      {bankTransferPaymentMethod}
      <CardContent title={t('typography.noneSupplied')} />
    </>
  ) : (
    <PaymentTypeWithButton
      title={t('typography.paymentMethod')}
      text={t('typography.noneSupplied')}
      refresh={paymentMethodRefresh}
    />
  )
}
