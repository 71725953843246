import { AgentVerification, Organisation, Partner } from '@percent/admin-dashboard/api/types'

export enum AgentVerificationModal {
  APPROVE,
  REJECT,
  APPROVE_EMAIL,
  UPDATE_STATUS
}

export type AgentVerificationDetailsProps = {
  orgData: Organisation | null
  partnerData: Partner | null
  orgPartnerLoader: boolean
  orgPartnerError: string
  data: AgentVerification | null
  isLoading: boolean
  errorMessage: string
  refresh: () => void
  aproveEmail: (payload: { id?: string }) => Promise<() => void>
}
