import {
  CurrencyCode,
  currencyMap,
  toMajorUnits,
  toMinorUnits,
} from './currency'

export type Money = {
  amount: number
  currency: CurrencyCode
}

export const createMoney = (major: number, currency: CurrencyCode): Money => ({
  amount: toMinorUnits(major, currency),
  currency,
})

type MoneyOptions = Omit<
  Intl.NumberFormatOptions,
  'currency' | 'currencySign'
> & {
  locale?: string
  forceLocale?: boolean
  hideDecimalsIfZero?: boolean
  hideDecimals?: boolean
}

const showUSDNarrowSymbolOnly = ({
  currencyCode,
  locale,
}: {
  currencyCode: string
  locale: string
}) => currencyCode === 'USD' && locale === 'en-GB'

export const formatMoney = (
  amount: Money,
  {
    locale,
    forceLocale = false,
    hideDecimalsIfZero = false,
    hideDecimals = false,
    ...options
  }: MoneyOptions = {
    locale: 'en-US',
    forceLocale: false,
  }
): string => {
  const minorUnits = currencyMap[amount.currency].minorUnits
  const major = toMajorUnits(amount.amount, amount.currency)

  const showUsdNarrow =
    !forceLocale &&
    showUSDNarrowSymbolOnly({
      currencyCode: amount.currency,
      locale: locale ?? 'en-US',
    })
  return new Intl.NumberFormat(showUsdNarrow ? 'en-US' : locale ?? 'en-US', {
    style: 'currency',
    currency: amount.currency,
    minimumFractionDigits:
      hideDecimals || (hideDecimalsIfZero && Math.floor(major) === major)
        ? 0
        : minorUnits,
    maximumFractionDigits: hideDecimals ? 0 : minorUnits,
    ...options,
  }).format(major)
}

type ShortFormatAmountProps = Omit<
  MoneyOptions,
  'notion' | 'compactDisplay'
> & {
  hideCurrencyIfLong?: boolean
  hideDecimalsIfLarge?: boolean
}

const safeFormat = (amount: Money, props: MoneyOptions) => {
  try {
    return formatMoney(amount, props)
  } catch (err) {
    if (err instanceof RangeError && err.message.includes('currencyDisplay')) {
      return formatMoney(amount, { ...props, currencyDisplay: 'symbol' })
    }
    throw err
  }
}

export const formatMoneyShort = (
  amount: Money,
  { hideCurrencyIfLong = true, ...props }: ShortFormatAmountProps
) => {
  let formatted = safeFormat(amount, {
    ...props,
    currencyDisplay: 'narrowSymbol',
    notation: 'compact',
    compactDisplay: 'short',
    hideDecimalsIfZero: true,
    maximumSignificantDigits:
      toMajorUnits(amount.amount, amount.currency) > 1000 ? 3 : undefined,
    // hideDecimals: toMajorUnits(amount.amount, amount.currency) > 1000,
  })
  if (hideCurrencyIfLong) formatted = formatted.replace(amount.currency, '')
  return formatted.trim()
}

export const sumMoney = (...monies: [Money, ...Money[]]) => {
  const currency = monies[0].currency
  if (monies.some((m) => m.currency !== currency))
    throw Error('all currencies must be the same when summing together money')
  return { amount: monies.reduce((acc, m) => acc + m.amount, 0), currency }
}
export const subtractMoney = (...monies: [Money, ...Money[]]) => {
  const currency = monies[0].currency
  if (monies.some((m) => m.currency !== currency))
    throw Error('all currencies must be the same when summing together money')
  return { amount: monies.reduce((acc, m) => acc - m.amount, 0), currency }
}
