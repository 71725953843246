import { BankDetailsStatusAction } from './BankDetailsStatusAction'
import { BankDetailsNoteAction } from './BankDetailsNoteAction'
import { BankDetailsDocumentAction } from './BankDetailsDocumentAction'
import { BankDetailsTimelineActionProps } from './BankDetailsTimelineAction.types'
import { logger } from '@percent/admin-dashboard/common/utility/logger'

export function BankDetailsTimelineAction({ log }: BankDetailsTimelineActionProps) {
  switch (log.affectedEntity) {
    case 'document':
      return <BankDetailsDocumentAction log={log} />
    case 'note':
      return <BankDetailsNoteAction log={log} />
    case 'review':
      return <BankDetailsStatusAction log={log} />
    default:
      logger(new Error(`unexpected log entity: ${JSON.stringify(log)}`))

      return null
  }
}
