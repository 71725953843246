import { Redirect, Route, type RouteProps } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTimeout } from 'usehooks-ts'

import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { Spinner } from '@percent/lemonade'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'
import { DEFAULT_ROUTE } from './default-route'

type FeatureFlagKey = keyof Omit<ReturnType<typeof useFeatureFlag>, 'isReady' | 'isTimedout'>

export type FeatureRouteProps<Path extends string = string> = RouteProps<Path> & {
  flag: FeatureFlagKey
}
export function FeatureRoute<Path extends string = string>({ flag, component, ...props }: FeatureRouteProps<Path>) {
  const { isReady, isTimedout, [flag]: flagValue } = useFeatureFlag()
  const { permissionsSet } = useAuthState()

  const [loaded, setLoaded] = useState(false)
  const [haveWaited, setHaveWaited] = useState(false)

  useEffect(() => {
    if (permissionsSet && (isReady || isTimedout) && !loaded) {
      setLoaded(true)
    }
  }, [isReady, isTimedout, loaded, permissionsSet])

  // if not set after 2 seconds, indicate we should not wait for permissions to be set
  useTimeout(() => {
    setHaveWaited(true)
  }, 2000)

  useEffect(() => {
    if (!loaded && haveWaited && (isReady || isTimedout)) {
      setLoaded(true)
    }
  }, [haveWaited, isReady, isTimedout, loaded])

  return loaded && !flagValue ? (
    <Redirect data-testid="redirect" to={DEFAULT_ROUTE} />
  ) : (
    <Route {...props} component={flagValue ? component : Spinner} />
  )
}
