import { useTranslation } from 'react-i18next'
import { format, isValid } from 'date-fns'

import styles from '../DomainVerification.module.scss'

import { Alert, Badge, CardContent, CardDashboard, Spacer, Tooltip } from '@percent/lemonade'
import { DomainAnalysisProps } from './DomainAnalysis.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'
import { DomainSource } from '@percent/admin-dashboard/api/types'

const getCardTitle = (source: DomainSource): LocaleKey => {
  switch (source) {
    case 'organisation_website':
      return 'typography.organisationWebsiteAnalysis'
    case 'organisation_email':
      return 'typography.organisationEmailAnalysis'
    case 'applicant_email':
      return 'typography.agentDomainAnalysis'
    case 'suggested_website':
      return 'typography.suggestedWebsiteAnalysis'
    default:
      return source as LocaleKey
  }
}

export function DomainAnalysis({ domain: domainAnalysis }: Readonly<DomainAnalysisProps>) {
  const { t } = useTranslation()

  const { domain, source, whoisData, geoData, flags } = domainAnalysis

  const location = [geoData?.location?.city, geoData?.location?.region, geoData?.location?.country].filter(
    f => f !== undefined
  )
  const domainAge = whoisData?.estimatedDomainAge ? `${whoisData?.estimatedDomainAge} days` : null
  const notAvailable = t('status.na')

  return (
    <CardDashboard title={t(getCardTitle(source))} data-testid="domain-analysis">
      {flags && flags?.length > 0 && (
        <>
          <Alert variant="warning" title={t('typography.domainFlags')}>
            <ul className={styles.fraudList}>
              {flags.map(flag => (
                <Tooltip content={t(`domainVerification.flag.${flag}.description`)} key={flag}>
                  <li>{t(`domainVerification.flag.${flag}`)}</li>
                </Tooltip>
              ))}
            </ul>
          </Alert>
          <Spacer axis="vertical" size={4} />
        </>
      )}

      <CardContent title={t('typography.domain')}>
        <a href={domain?.includes('https') ? domain : `https://${domain}`} target="_blank" rel="noreferrer">
          {domain || notAvailable}
        </a>
      </CardContent>
      <CardContent title={t('typography.source')}>
        <Badge variant="default">{source}</Badge>
      </CardContent>
      <CardContent title={t('typography.registrationDate')}>
        <span>
          {whoisData?.createdDate && isValid(new Date(whoisData?.createdDate))
            ? format(new Date(whoisData?.createdDate), 'dd MMMM yyyy')
            : notAvailable}
        </span>
      </CardContent>
      <CardContent title={t('typography.domainAge')}>
        <span>{domainAge || notAvailable}</span>
      </CardContent>
      <CardContent title={t('typography.registrar')}>
        <span>{whoisData?.registrarName || notAvailable}</span>
      </CardContent>
      <CardContent title={t('typography.location')}>
        <span>{location.length > 0 ? location.join(', ') : notAvailable}</span>
      </CardContent>
      <CardContent title={t('typography.ip')}>
        <span>{geoData?.ip || notAvailable}</span>
      </CardContent>
      <CardContent title={t('typography.isp')}>
        <span>{geoData?.isp || notAvailable}</span>
      </CardContent>
      <CardContent title={t('typography.asn')}>
        <span>{geoData?.as?.asn || notAvailable}</span>
      </CardContent>
    </CardDashboard>
  )
}
