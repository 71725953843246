import { ArrayParam, StringParam, useQueryParams } from 'use-query-params'

import { DisbursementsTable } from './disbursementsTable/DisbursementsTable'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'

export function DisbursementsContainer() {
  const { disbursementsService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    allDisbursementsWithStatus: ArrayParam,
    anyDisbursementsWithStatus: ArrayParam,
    allDisbursementsWithTypes: ArrayParam,
    anyDisbursementsWithTypes: ArrayParam,
    foundationId: StringParam
  })

  const [
    { totalResults, isLoading, dataOrNull, errorMessageOrNull },
    { query, nextPage, previousPage, refreshToFirstPage }
  ] = useQueryList(disbursementsService.getDisbursementBatchesList, {
    ...queryParams
  })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <DisbursementsTable
      isLoading={isLoading}
      totalResults={totalResults}
      data={dataOrNull}
      previousPage={previousPage}
      nextPage={nextPage}
      errorMessage={errorMessageOrNull}
      refreshToFirstPage={refreshToFirstPage}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
